import { WEBGL } from 'three/examples/jsm/WebGL.js';
import { Viewer } from './viewer.js';
import { SimpleDropzone } from 'simple-dropzone';
import queryString from 'query-string';
import { ZipLoader } from './ziploader.js';
import { Vector3,Vector2, SphereGeometry, MeshBasicMaterial, Mesh, AudioListener, Audio, AudioLoader } from 'three';
import domtoimage from 'dom-to-image';
import html2canvas from "html2canvas";
if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
  console.error('The File APIs are not fully supported in this browser.');
} else if (!WEBGL.isWebGLAvailable()) {
  console.error('WebGL is not supported in this browser.');
}

class App {

  /**
   * @param  {Element} el
   * @param  {Location} location
   */
  constructor(el, location) {

    const hash = location.hash ? queryString.parse(location.hash) : {};
    this.options = {
      kiosk: Boolean(hash.kiosk),
      model: hash.model || '',
      preset: hash.preset || '',
      cameraPosition: hash.cameraPosition
        ? hash.cameraPosition.split(',').map(Number)
        : null,
      showgui: false,
    };

    this.el = el;
    this.viewer = null;
    this.viewerEl = null;
    this.spinnerEl = el.querySelector('.spinner');
    this.url = null;
    this.urlJson = null;
    // this.audio = this.createAudioPlayer();
    // this.audioLoader = this.createAudioLoader();
    this.hideSpinner();

    const options = this.options;

    if (options.kiosk) {
      const headerEl = document.querySelector('header');
      headerEl.style.display = 'none';
    }

    if (options.model) {
      this.view(options.model, '', new Map());
    }

  }

  /**
   * Sets up the view manager.
   * @return {Viewer}
   */
  createViewer() {
    this.viewerEl = document.createElement('div');
    this.viewerEl.classList.add('viewer');
    document.body.appendChild(this.viewerEl);
    this.viewer = new Viewer(this.viewerEl, this.options);
    return this.viewer;
  }

  createAudioPlayer()
  {
    let listener = new AudioListener();
    let audio = new Audio(listener);
  
    return audio;
  }

  createAudioLoader()
  {
    let audioLoader = new AudioLoader();
    return audioLoader;
  }

  LoadAudioRes(path, action) {
    let audioLoader = this.createAudioLoader();
    audioLoader.load(path, function (buffer) {
      action(buffer);
    })
  }

  CreateClickAudioPlayer(path) {
    let clickAudio = this.createAudioPlayer();
    this.LoadAudioRes(path,function(buffer)
    {
      clickAudio.setBuffer(buffer);
      clickAudio.setLoop(false);
      clickAudio.setVolume(window.videoVolume);
    })
    return clickAudio;
  }

  CreatepPangbaiAudioPlayer(path) {
    let pangbaiAudio = this.createAudioPlayer();
    console.log("Create Pangbai Audio Player");
    this.LoadAudioRes(path,function(buffer)
    {
      pangbaiAudio.setBuffer(buffer);
      pangbaiAudio.setLoop(false);
      pangbaiAudio.setVolume(window.videoVolume);
      function OnAudioLoadFinish()
      {
        console.log("audio play");
        pangbaiAudio.play();
        pangbaiAudio.pause();
        pangbaiAudio.play();
        window.removeEventListener('touchstart',OnAudioLoadFinish);
      }
      window.addEventListener('touchstart', OnAudioLoadFinish);
      pangbaiAudio.play();
      window.videoList.push(pangbaiAudio);
    })
    
    return pangbaiAudio;
  }
  
  CreateBgmAudioPlayer(path) {
    let bgmAudio = this.createAudioPlayer();
    console.log("Create Bgm Audio Player");
    this.LoadAudioRes(path, function (buffer) {
      bgmAudio.setBuffer(buffer);
      bgmAudio.setLoop(true);
      bgmAudio.setVolume(window.videoVolume);
      window.videoList.push(bgmAudio);
      function OnAudioLoadFinish()
      {
        console.log("audio play");
        bgmAudio.play();
        bgmAudio.pause();
        bgmAudio.play();
        window.removeEventListener('touchstart',OnAudioLoadFinish);
      }
      window.addEventListener('touchstart', OnAudioLoadFinish);
      bgmAudio.play();
    })
    return bgmAudio;
  }

  CreateAudioPlayerList(bgmPath,pangbaiPath,muteBtnName,voiceBtnName)
  {
    var nowVolume = window.videoVolume;
    let bgmAudio = window.app.CreateBgmAudioPlayer(bgmPath);
    let pangbaiAudio = window.app.CreatepPangbaiAudioPlayer(pangbaiPath);

    let muteBtn = GetMutedBtnElement(muteBtnName);
    muteBtn.onclick = function () {
      // if (bgmAudio.getVolume() == 0) {
      //   nowVolume = window.videoVolume;
      // } else {
      //   nowVolume = 0;
      // }
      window.isMute=false;
      muteBtn.style.display="none";
			voiceBtn.style.display="block";
      nowVolume = window.videoVolume;
      bgmAudio.setVolume(nowVolume);
      pangbaiAudio.setVolume(nowVolume);
    }
    
    let voiceBtn = GetVoiceBtnElement(voiceBtnName);
    voiceBtn.onclick = function () {
      // if (bgmAudio.getVolume() == 0) {
      //   nowVolume = window.videoVolume;
      // } else {
      //   nowVolume = 0;
      // }
      window.isMute=true;
      muteBtn.style.display="block";
      voiceBtn.style.display="none";
      nowVolume = 0;
      bgmAudio.setVolume(nowVolume);
      pangbaiAudio.setVolume(nowVolume);
    }
    return { bgm: bgmAudio, pangbai: pangbaiAudio };
  }

  OnPlayVideo()
  {
    for (let i = 0; i < window.videoList.length; i++) {
      const element = window.videoList[i];
      element.setVolume(0);
    }
  }

  OnVideoEnd()
  {
    for (let i = 0; i < window.videoList.length; i++) {
      const element = window.videoList[i];
      if(window.isMute)
      {
        element.setVolume(0);
      }else{
        element.setVolume(window.videoVolume);
      }
    }
  }




  /**
   * Loads a fileset provided by user action.
   * @param  {Map<string, File>} fileMap
   */
  load(fileMap) {
    let rootFile;
    let rootPath;
    Array.from(fileMap).forEach(([path, file]) => {
      if (file.name.match(/\.(gltf|glb)$/)) {
        rootFile = file;
        rootPath = path.replace(file.name, '');
      }
    });

    if (!rootFile) {
      this.onError('No .gltf or .glb asset found.');
    }

    this.view(rootFile, rootPath, fileMap);
  }

  /**
   * Passes a model to the viewer, given file and resources.
   * @param  {File|string} rootFile
   * @param  {string} rootPath
   * @param  {Map<string, File>} fileMap
   */
  view(rootFile, rootPath, fileMap) {

    if (this.viewer) this.viewer.clear();

    const viewer = this.viewer || this.createViewer();

    const fileURL = typeof rootFile === 'string'
      ? rootFile
      : URL.createObjectURL(rootFile);

    const cleanup = () => {
      this.hideSpinner();
      if (typeof rootFile === 'object') URL.revokeObjectURL(fileURL);
    };

    viewer
      .load(fileURL, rootPath, fileMap)
      .catch((e) => this.onError(e))
      .then((gltf) => {
        if (!this.options.kiosk) {
          //this.validationCtrl.validate(fileURL, rootPath, fileMap, gltf);
        }
        cleanup();
      });
  }

  /**
   * @param  {Error} error
   */
  onError(error) {
    let message = (error || {}).message || error.toString();
    if (message.match(/ProgressEvent/)) {
      message = 'Unable to retrieve this file. Check JS console and browser network tab.';
    } else if (message.match(/Unexpected token/)) {
      message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
    } else if (error && error.target && error.target instanceof Image) {
      message = 'Missing texture: ' + error.target.src.split('/').pop();
    }
    window.alert(message);
    console.error(error);
  }

  showSpinner() {
    this.spinnerEl.style.display = '';
  }

  hideSpinner() {
    if(this.spinnerEl)
    {
      this.spinnerEl.style.display = 'none';
    }
  }

  InTriangleTest(tri, p) {
    return this.InTriangleVecTest(tri[0], tri[1], tri[2], p);
  }

  InTriangleVecTest(a, b, c, p) {
    return this.ToLeft(a, b, p) && this.ToLeft(b, c, p) && this.ToLeft(c, a, p);
  }

  ToLeft(a, b, p) {
    return (a.x * b.z + b.x * p.z + p.x * a.z - a.z * b.x - b.z * p.x - p.z * a.x) > 0;
  }

  CreateSphereWithScale(pos, radius, color) {
    var geometry = new SphereGeometry(radius);
    var material = new MeshBasicMaterial({ color: color });
    var sph = new Mesh(geometry, material);
    if (this.viewer.scene == null) {
      alert("null");
    }
    this.viewer.scene.add(sph);
    sph.position.copy(pos);
    // console.log("Create success, position  x is " + pos.x + ",y is " + pos.y + ",z is " + pos.z);
    return sph;
  }

  CreateSphere(pos) {
    var geometry = new SphereGeometry(0.5, 0.5, 0.5);
    var material = new MeshBasicMaterial({ color: 0x00ff00 });
    var sph = new Mesh(geometry, material);
    if (this.viewer.scene == null) {
      alert("null");
    }
    this.viewer.scene.add(sph);
    sph.position.copy(pos);
    // console.log("Create success, position  x is " + pos.x + ",y is " + pos.y + ",z is " + pos.z);
    return sph;
  }

  SetToLeftOrder(triangle) {
    let a = new Vector3().subVectors(triangle[0], triangle[1]);
    let b = new Vector3().subVectors(triangle[1], triangle[2]);
    var up = a.cross(b);
    if (up.y > 0) {
      return [triangle[2], triangle[1], triangle[0]];
    } else {
      return [triangle[1], triangle[0], triangle[2]];
    }
  }

  ProjectTriangelToXZ(triangel) {
    let triCopy = [];
    triangel.forEach(e => {
      let newE = new Vector3().copy(e).setY(0);
      triCopy.push(newE);
    });
    return triCopy;
  }

  IsPosValid(navMesh, pos) {
    let isPosValid = false;
    for (let i = 0; i < navMesh.length; i++) {
      const element = navMesh[i];
      var triProjectXZ = this.ProjectTriangelToXZ(element);
      triProjectXZ = this.SetToLeftOrder(triProjectXZ);
      let testResult = this.InTriangleTest(triProjectXZ, pos);
      if (testResult) {
        isPosValid = true;
        break;
      }
    }
    return isPosValid;
  }

  GetOwnerTriangle(navMesh, pos) {
    for (let i = 0; i < navMesh.length; i++) {
      const element = navMesh[i];
      var triProjectXZ = this.ProjectTriangelToXZ(element);
      triProjectXZ = this.SetToLeftOrder(triProjectXZ);
      let testResult = this.InTriangleTest(triProjectXZ, pos);
      if (testResult) {
        return element;
      }
    }
    return null;
  }

  FindOverEdge(triangle, pos) {
    let edge = this.ToLeft(triangle[0], triangle[1], pos);
    if (!edge) {
      return [triangle[0], triangle[1]];
    }
    edge = this.ToLeft(triangle[1], triangle[2], pos);
    if (!edge) {
      return [triangle[1], triangle[2]];
    }
    edge = this.ToLeft(triangle[2], triangle[0], pos);
    if (!edge) {
      return [triangle[2], triangle[0]];
    }
    console.log("ERROR!!!");
    return null;
  }

  ShowHint(hintPath) {
    var hint = OpenNormalPictureWithoutExit(hintPath);
    window.canClick = false;
    window.addEventListener('click', hintClick);

    function hintClick() {
      console.log(hint);
      window.canClick = true;
      window.removeEventListener('click', hintClick);
      hint.hide();
    }
  }

  hintClick(e) {
    if(!window.isSearching)
    {
      return;
    }
    let x = event.clientX;
    let y = event.clientY;
    // console.log("x: " +x);
    // console.log("y: " +y);
    
    let pic = document.getElementsByClassName("viewer-canvas")[0].children[0];
    let width = pic.width;
    let height = pic.height;
    let left = parseFloat(pic.style.marginLeft.replace("px",""));
    let top = parseFloat(pic.style.marginTop.replace("px",""));
    
    let offsetX = width * 0.01;
    let offsetY = height * 0.15;
    let cellX = (width - offsetX ) / 6;
    let cellY = (height - offsetY) / 7;
    let xIndex = parseInt((x - left - offsetX) / cellX);
    let YIndex = parseInt((y - top - offsetY) / cellY);
    // console.log("xIndex: " +xIndex);
    // console.log("YIndex: " +YIndex);
    
    let json = window.configJson.canzhanList;
    let potsJson = window.configJson.LogoDetailPoints;
    if (xIndex >= 0 && YIndex >= 0 && xIndex <= 6 && YIndex <= 7)
    {
      for (let index = 0; index < json.length; index++) {
        const ele = json[index];
        if(ele.col==xIndex && ele.row==YIndex)
        {
          let pot = potsJson[ele.index];
          PushTrackEvent('点击logo', pot.name);
          OpenPicture(pot);
          document.getElementsByClassName('bottom-btns-top')[0].style.display = 'none';
          window.isopentop = true;
          window.isSearching=false;
          window.removeEventListener('click', window.app.hintClick);
          break;
        }
      }
    }
  }

  

  SavePicture(pic,config)
  {
     
    // domtoimage.toSvg(pic, { quality: 0.95 }).then(function (dataUrl) {
    //       // var link = document.createElement('a');
    //       // var time = new Date();
    //       // link.download = time.toLocaleString()+'.jpg';
    //       // link.href = dataUrl;
    //       // link.click();
    //       // var img = $('.viewer-canvas img');
    //       // img.attr('src',dataUrl); 
    //       // $('#img-text').remove();
          
    //       if (dataUrl != "error") {
    //         alert(dataUrl);
           
    //       }
    //   });    
    html2canvas(pic,config).then(canvas => {
      var dt = canvas.toDataURL('image/jpeg');      		
      var img = document.getElementById("postcard");//  $('.viewer-canvas img');     
      img.src=dt;
      document.getElementById("img-text").remove(); 
      $('#img-text').remove();
		});
  }

}


var app = null;
 window.AppInit = function()
{
  app = new App(document.body, location);
  window.app = app;
  window.canClick=true;
  var configJson = "assets/config.json";
  var request = new XMLHttpRequest();

  request.open("get", configJson);
  request.send(null);
  request.onload = function () {
    if (request.status == 200) {
      var json = JSON.parse(request.responseText);
      app.urlJson = json;
      window.configJson = json;
      app.url = json.GameYa;
      if (paramJson != null) {
        console.log(paramJson.scene);
        if (paramJson.scene == undefined) {
          // OnEnterVideoPlay(app);
          if(paramJson.raw == 'true')
          {
            console.log("raw");
            app.view(app.url.tvtower[0].toString(), '', new Map());
          }
          else
          {
            console.log("zip");
            app.view(app.url.tvtower[1].toString(), '', new Map());
          }
        } else {
          console.log(app.url[paramJson.scene]);
          if(paramJson.raw == 'true')
          {
            console.log("raw");
            app.view(app.url[paramJson.scene][0], '', new Map());
          }
          else
          {
            console.log("zip");
            app.view(app.url[paramJson.scene][1], '', new Map());
          }
        }
      }
    }
  }
}





// document.addEventListener('DOMContentLoaded', () => {

//   app = new App(document.body, location);
//   window.app = app;
//   var configJson = "assets/config.json";
//   var request = new XMLHttpRequest();

//   request.open("get", configJson);
//   request.send(null);
//   request.onload = function () {
//     if (request.status == 200) {
//       var json = JSON.parse(request.responseText);
//       app.urlJson = json;
//       window.configJson = json;
//       app.url = json.GameYa;
//       if (paramJson != null) {
//         console.log(paramJson.scene);
//         if (paramJson.scene == undefined) {
//           // OnEnterVideoPlay(app);
//           app.view(app.url.tvtower.toString(), '', new Map());
//         } else {
//           console.log(app.url[paramJson.scene]);
//           app.view(app.url[paramJson.scene], '', new Map());
//         }
//       }
//     }
//   }
// });
