export class Game {
  constructor() {

  }
  // dot: document.querySelector('.dot'),
  // maxLeft: document.getElementById('right').offsetWidth - 60,
  // maxBottom: document.getElementById('right').offsetHeight - 60,


  up(val) {
    this.updatePos('bottom', val);
  }

  right(val) {
    this.updatePos('left', val)
  }

  left(val) {
    this.updatePos('left', -val);
  }

  back(val) {
    this.updatePos('bottom', -val);
  }

  updatePos(pos, val) {
    // var old = parseInt(getComputedStyle(Game.dot).getPropertyValue(pos));
    // var newVal = val + old;

    // if (!checkedExceed(pos, newVal)) return;

    // Game.dot.style[pos] = val * 0.1 + old + 'px';
  }

  // getDotPos(pos) {
  //   return parseInt(getComputedStyle(Game.dot).getPropertyValue(pos))
  // }

  // setDotPos(pos, val) {
  //   Game.dot.style[pos] = val + 'px';
  // }

  // checkedExceed(pos, val) {
  //   switch (pos) {
  //     case 'left':
  //       if (val <= 0) {
  //         return false
  //       } else if (val >= Game.maxLeft) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //       break;
  //     case 'bottom':
  //       if (val >= Game.maxBottom) {
  //         return false;
  //       } else if (val <= 20) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //       break;
  //   }
  //   return true;
  // }


  stop() {
    // 
  }
}
// pay();