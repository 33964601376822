import { Viewer } from './viewer.js';
import { Vector3, Raycaster, Vector2, Color, Euler } from 'three';
import { Joystick } from "./joystick.js";
/*
class BaseState
{
	constructor (options)
	{
		this.name = options.name;
		this.camera = options.camera;

		this.begin = function()
		{

		}

		this.end = function()
		{

		}

		this.isOver = function()
		{

		}

		this.update = function(deltaTime)
		{
			console.log('=------');
		}
	}
}
*/

/*
this.defaultCamera.position.x = 0;
this.defaultCamera.position.y = 13;
this.defaultCamera.position.z = -40;
this.controls.target = new Vector3(0.0, 10, 0.0);

this.defaultCamera.position.x = 0;
this.defaultCamera.position.y = 20;
this.defaultCamera.position.z = -7;
this.controls.target = new Vector3(0.0, 20, 10.0);

-3.2653076981286837, y: 122.46687236701582, z: 449.2028617138161}
viewer.js:269 Vector3 {x: -33.67245488013309, y: 120.88745398604473, z: 3.2812605880604657
*/

class StartState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;

		this.duration = 5.0;
		this.timer = 0.0;

		this.isOver = false;

		this.navMesh = [];

		this.start = function () {
			console.log('start state: ' + this.name);
			// ShowBackIcon();
			let jsonvalue1 = window.configJson.IconPaths.fangaoshuoming;
			let jsonvalue2 = window.configJson.IconPaths.fangaofenxiang;

			ShowAndChangePictureTopEX(jsonvalue1, jsonvalue2);
			PushTrackEvent('商汤','访问');
			//this.params.camera.position.set(-3.2653076981286837, 122.46687236701582, 449.2028617138161);

			//this.params.controls.target.set(-33.67245488013309, 120.88745398604473, 3.2812605880604657);

			window.app.ShowHint(window.configJson.SceneIntro.vangogh);
		}

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}

			if (this.timer >= this.duration) {
				this.isOver = true;

				this.end();
			}
			else {

			}

			this.timer += deltaTime;
		}
	}
}

class FreeMoveState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;

		this.duration = 1.0;
		this.timer = 0.0;
		this.isOver = false;
		var euler = new Euler(0, 0, 0, 'YXZ');
		var PI_2 = Math.PI / 2;
		var vec = new Vector3();
		var old = new Vector3();
		var orbCamera = this.params.camera;
		this.sensitivity = 1;
		var speed = window.configJson.MoveSpeed.vangogh;
		this.start = function () {
			console.log('start state: ' + this.name);
			// window.addEventListener('mousemove', this.onMouseOrTouchDown, false);
			this.CreateJoyStick();
			this.addAllEventListener();
			let videoWhite = window.configJson.IconPaths.whiteVideo;
			let videoPath = window.configJson.VideoPaths.vangogh;
			window.app.viewer.addImageObject(videoWhite, function () {
				lightbox_open(videoPath);
				PushTrackEvent('商汤','查看梵高视频');
			}, new Vector3(1, 0.2, -1.3), new Vector3(0.5, 0.5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				
			}, true);

			let jsonPoints = window.configJson.LogoDetailPoints;
			const jsonPoint = jsonPoints[8];
			let path = window.configJson.IconPaths.pinpai_intro;
			window.app.viewer.addImageObject(path, function () {
				if (GetOpenState() == false) {
					OpenPicture(jsonPoint);
					PushTrackEvent('商汤','查看品牌介绍');
					document.getElementsByClassName('bottom-btns-top-ex')[0].style.display = 'none';
					window.isopentopex=true;
				}

			}, new Vector3(0.5, -0.2, -0.6), new Vector3(1, 0.5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				
			}, true);

			let bgmPath = window.configJson.AudioPath.fangaobgm;
			let pangbaiPath = window.configJson.AudioPath.fangaopangbai;
			window.app.CreateAudioPlayerList(bgmPath,pangbaiPath,"jingying","shengying");
		}

		
		this.addAllEventListener = function () {
			window.renderer.domElement.addEventListener('mousedown', this.downClbk);
			window.renderer.domElement.addEventListener("touchstart", this.downClbk);
			window.renderer.domElement.addEventListener('mouseup', this.upClbk);
			window.renderer.domElement.addEventListener("touchend", this.upClbk);
			window.renderer.domElement.addEventListener("touchcancel", this.upClbk);
		}

		this.CreateJoyStick = function () {
			var joy = new Joystick({ zone: document.getElementById("left") }).init();
			joy.onStart = function (distance, angle) {
				old.copy(orbCamera.position);
				let val = distance * delTime * speed;
				switch (angle) {
					case 'up':
						self.moveForward(val);
						break;
					case 'right':
						self.moveRight(val);
						break;
					case 'left':
						self.moveLeft(val);
						break;
					case 'down':
						self.moveBack(val);
						break;
				};
			}
		}

	
		var self = this;
		var mouse = new Vector2();
		this.downClbk = function (event) {
			if (event.type == "touchstart") {
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			}
			window.renderer.domElement.addEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.addEventListener("touchmove", self.moveClbk);
		}

		this.upClbk = function (e) {
			window.renderer.domElement.removeEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.removeEventListener("touchmove", self.moveClbk);
		}

		this.moveClbk = function (event) {
			var movementX = null;
			var movementY = null;
			euler.setFromQuaternion(orbCamera.quaternion);
			if (event.type == "touchmove") {
				movementX = (event.touches[event.touches.length - 1].clientX - mouse.x) * 3 || 0;
				movementY = (event.touches[event.touches.length - 1].clientY - mouse.y) * 3 || 0;
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			} else {
				movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
				movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;
			}
			euler.y -= movementX * 0.002;
			euler.x -= movementY * 0.002;
			euler.x = Math.max(- PI_2, Math.min(PI_2, euler.x));
			orbCamera.quaternion.setFromEuler(euler);
		}

		this.checkDisValLeagel = function (vec, distance) {
			let nextPos = new Vector3();
			let normalizedVec = new Vector3();
			normalizedVec.copy(vec);
			normalizedVec.normalize();
			nextPos.copy(orbCamera.position);
			nextPos.addScaledVector(normalizedVec, distance);
			if (nextPos.x < 0.8 && nextPos.x > -0.8 && nextPos.z < 2 && nextPos.z > -0.64) {
				return { vec: normalizedVec, distance: distance };
			}
			return { vec: new Vector3(), distance: distance };
			let length = nextPos.length();
			return { vec: normalizedVec, distance: distance };
			if (length < 48 && length > 14) {
				return { vec: normalizedVec, distance: distance };
			} else {
				//var newVec = new Vector3();
				var temp = new Vector3();
				var toCenterDir = new Vector3();
				toCenterDir.setX(-orbCamera.position.x);
				toCenterDir.setZ(-orbCamera.position.z);
				toCenterDir.normalize();
				// this.outputVec("toCenterDir: ", toCenterDir);

				let toCenterVal = vec.dot(toCenterDir);
				// console.log("toCenterVal: " + toCenterVal);
				toCenterDir.x = toCenterDir.x * toCenterVal;
				toCenterDir.y = toCenterDir.y * toCenterVal;
				toCenterDir.z = toCenterDir.z * toCenterVal;
				vec.sub(toCenterDir);
				var d = vec.length();

				// this.outputVec("Vec after Normalize: ", vec);

				// newVec.setX(vec.x);
				// newVec.setZ(vec.z);
				// temp.setX(orbCamera.position.x);
				// temp.setZ(orbCamera.position.z);
				// var result = newVec.add(temp);

				// var ttt = new Vector3();
				// ttt.copy(orbCamera.position).normalize()*;
				// result.addScalar();
				return { vec: vec, distance: d };
			}
		}
		this.outputVec = function (name, vec) {
			console.log(name + ": " + vec.x + "," + vec.y + "," + vec.z);
		}

		this.moveForward = function (distance) {
			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			//获取四元数中x项
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			//与up叉乘算出forward;
			vec.crossVectors(orbCamera.up, vec);
			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.moveBack = function (distance) {
			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			//获取四元数中x项
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			//与up叉乘算出forward;
			vec.setX(-vec.x);
			vec.setY(-vec.y);
			vec.setZ(-vec.z);
			vec.crossVectors(orbCamera.up, vec);

			let opts = this.checkDisValLeagel(vec, distance);
			// if (true) {
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
			// }
		};

		this.moveRight = function (distance) {
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.moveLeft = function (distance) {
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			vec.setX(-vec.x);
			vec.setY(-vec.y);
			vec.setZ(-vec.z);
			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		var delTime = 0.02;
		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}
			//this, this.outputVec("now pos: ", orbCamera.position);
		}
	}
}

export class StateMachine_vangogh {
	constructor(options) {
		this.states = [];
		this.activeState = -1;
		this.isStarted = false;
		this.options = options;

		this.start = function () {
			this.isStarted = true;

			this.states[this.activeState].start();
		}

		this.initializeStates = function () {
			this.activeState = 0;

			this.states.push(new StartState({ name: 'StartState', params: this.options }));
			this.states.push(new FreeMoveState({ name: 'FreeMove', params: this.options }));
		}

		this.update = function (deltaTime) {
			if (this.isStarted == false) {
				return;
			}

			if (this.activeState >= 0 && this.activeState < this.states.length) {
				this.states[this.activeState].update(deltaTime);

				if (this.states[this.activeState].isOver) {
					// Jump to next
					this.activeState++;

					if (this.activeState < this.states.length) {
						this.states[this.activeState].start();
					}
				}
			}
		}

		this.initializeStates();
	}
}
