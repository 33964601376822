import { Viewer } from './viewer.js';
import { Vector3, Raycaster, Vector2, Color, Euler, TextureLoader, sRGBEncoding } from 'three';
import { Joystick } from "./joystick.js";
import { Game } from "./JoyStickController.js";
import { MathUtils, Quaternion } from 'three/build/three.module';
const STAGE_START = 0;

/*
class BaseState
{
	constructor (options)
	{
		this.name = options.name;
		this.camera = options.camera;

		this.begin = function()
		{

		}

		this.end = function()
		{

		}

		this.isOver = function()
		{

		}

		this.update = function(deltaTime)
		{
			console.log('=------');
		}
	}
}
*/

/*
this.defaultCamera.position.x = 0;
this.defaultCamera.position.y = 13;
this.defaultCamera.position.z = -40;
this.controls.target = new Vector3(0.0, 10, 0.0);

this.defaultCamera.position.x = 0;
this.defaultCamera.position.y = 20;
this.defaultCamera.position.z = -7;
this.controls.target = new Vector3(0.0, 20, 10.0);
*/

class StartState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;
		this.owner = options.owner;

		this.duration = 3.0;
		this.timer = 0.0;

		this.isOver = false;

		this.start = function () {
			console.log('start state: ' + this.name);

			this.params.controls.enableZoom = false;
			this.params.controls.enablePan = false;
			this.params.controls.enableRotate = false;
			// OnEnterVideoPlay();
			let stateMachineBreaking = window.sessionStorage.getItem("statemachine_breaking");
			if(stateMachineBreaking != null && stateMachineBreaking == "true")
			{
				let fov = JSON.parse(window.sessionStorage.getItem("statemachine_breaking_pov"));
				let qua = JSON.parse(window.sessionStorage.getItem("statemachine_breaking_rot"));
				this.owner.ExcudeCmd("StayViewLogoState", {fov:fov, qua:qua});
				window.sessionStorage.removeItem("statemachine_breaking_pov");
				window.sessionStorage.removeItem("statemachine_breaking_rot");
				window.sessionStorage.removeItem("statemachine_breaking");

			}
			PushTrackEvent('主馆','访问');

		}

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}

			if (this.timer >= this.duration) {
				this.isOver = true;

				this.end();
			}
			else {

			}

			this.timer += deltaTime;
		}
	}
}

class InteractWithLogoState {
	constructor(options) {
		this.options = options.params;
		this.name = options.name;
		this.params = options.params;

		this.duration = 3.0;
		this.timer = 0.0;

		this.isOver = false;

		this.raycaster = new Raycaster();
		this.mouse = new Vector2();

		this.brandParams = {};

		this.start = function () {
			console.log('start state: ' + this.name);

			this.params.controls.enableZoom = true;
			this.params.controls.enablePan = true;
			this.params.controls.enableRotate = true;

			var param =
			{
				id: 'brand_0',
				target: new Vector3(50, 0, 50), // 注意：天空盒的半径为80
				enter: new Vector3(0, 0, 48), // 进入logo的点击点，Logo墙的半径为48
				exit: new Vector3(-30, 0, 45), // 退出时的点击点，建议使用2D UI作为返回
				isDebug: true,
			}

			this.setupBrandInteract(param);
		}

		this.setupBrandInteract = function (param) {
			this.brandParams[param.id] = param;

			this.setupBrandEnterPoint(param.id, param.enter, param.isDebug);

			this.setupBrandExitPoint(param.id, param.exit, param.isDebug);

			this.setupLogoTargetPoint(param.id, param.target, param.isDebug);
		}

		this.setupLogoTargetPoint = function (id, targetPoint, isDebug) {
			if (isDebug) {
				var geometry = new SphereBufferGeometry(10, 7, 7)
				var material = new MeshBasicMaterial({ color: new Color(0xff0000) });
				var sphere = new Mesh(geometry, material);

				sphere.position.x = targetPoint.x;
				sphere.position.y = targetPoint.y;
				sphere.position.z = targetPoint.z;

				this.options.viewer.scene.add(sphere);
			}
		}

		this.setupBrandEnterPoint = function (id, enterPoint, isDebug) {
			var radius = 6;

			var ss = this;

			this.options.viewer.addClickableSphere(function (markId) {
				console.log('image object is clicked: ' + markId);
				// 该markId与创建对象进传入的id一致，可以得到用户点击的具体logo对象
				ss.options.viewer.stateMachine_Main.showMainScene(false);

				ss.options.viewer.stateMachine_Main.lookAtTargetPoint(ss.brandParams[markId].target);

			}, enterPoint, radius, function (spherObject) {
				// 创建的点击对象需要隐藏时可以打开下一行代码
				if (isDebug) {

				}
				else {
					spherObject.material.visible = false;
				}
			}, id);
		}

		this.setupBrandExitPoint = function (id, exitPoint, isDebug) {
			var radius = 6;
			var ss = this;
			this.options.viewer.addClickableSphere(function (markId) {
				console.log('image object is clicked: ' + markId);
				// 该markId与创建对象进传入的id一致，可以得到用户点击的具体logo对象
				ss.options.viewer.stateMachine_Main.showMainScene(true);

				ss.options.viewer.stateMachine_Main.recoverLastCameraPosition();

			}, exitPoint, radius, function (imageObject) {
				// 创建的点击对象需要隐藏时可以打开下一行代码
				if (isDebug) {

				}
				else {
					imageObject.material.visible = false;
				}
			}, id, 0xffff00);
		}

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}

			if (false) {
				this.isOver = true;

				this.end();
			}
			else {

			}

			this.timer += deltaTime;
		}
	}
}

class StayViewLogoState{

	constructor(options)
	{
		this.name = options.name;
		this.params = options.params;
		this.owner = options.owner;
		var orbCamera = this.params.camera;
		var euler = new Euler(0, 0, 0, 'YXZ');
		var PI_2 = Math.PI / 2;
	
		let points = [];
		self = this;

		this.start = function(args)
		{
			console.log('start state: ' + this.name);
			
			let ownerMain = this.owner;
			ownerMain.showMainScene(false);
			if(args != null)
			{
				this.params.camera.fov = args.fov;
				this.params.camera.updateProjectionMatrix();
	
				this.params.camera.position.set(0, 0, 0);
				this.params.camera.quaternion.set(args.qua._x, args.qua._y, args.qua._z, args.qua._w);
			}
			this.owner.airShip.visible=true;	
			ShowBackToTVTower(function()
			{
				console.log("click back to tower");
				ownerMain.ExcudeCmd("FreeMoveState");
			});

			
			if(points != null & points.length != 0)
			{
				console.log("points already exist");
				for (let i = 0; i < points.length; i++) {
					const point = points[i];
					point.visible = true;
				}
			}
			else
			{
				console.log("create new points");
				function setPovit()
				{
					window.sessionStorage.setItem("statemachine_breaking", "true");
					window.sessionStorage.setItem("statemachine_breaking_pov", JSON.stringify(orbCamera.fov));
					window.sessionStorage.setItem("statemachine_breaking_rot", JSON.stringify(orbCamera.quaternion.clone()));
				}

				let jsonPoints = window.configJson.LogoDetailPoints;
				let path = window.configJson.IconPaths.guanggappai;
				// let path = window.configJson.IconPaths.whiteCircle;
				window.app.viewer.addImageObject(path, function () {
						if ( GetOpenState()==false) 
						{
								setPovit()
								PushTrackEvent('主馆', '跳转小游戏');
								window.open(window.configJson.GameYa.Url, '_self', false);
						}
				}, new Vector3(jsonPoints[0].pos[0], jsonPoints[0].pos[1], jsonPoints[0].pos[2]), new Vector3(20, 20, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
					// console.log(jsonPoint.name);
					points.push(imageObject);
				}, true);

			
				for (let i = 1; i < jsonPoints.length; i++) 
				{
					const jsonPoint = jsonPoints[i];
					window.app.viewer.addImageObject(path, function () {
						if (GetOpenState() == false) {
							if (jsonPoint.name == "大白兔") {
								PushTrackEvent('主馆', '跳转大白兔');
								setPovit()
								SceneMove("dabaitu");
							}
							else if (jsonPoint.name == "商汤") {
								PushTrackEvent('主馆', '跳转商汤');
								setPovit()
								SceneMove("vangogh");
							}
							else if (jsonPoint.name == "阅文集团") {
								PushTrackEvent('主馆', '跳转阅文');
								setPovit()
								SceneMove("yuewen");
							}
							else if (jsonPoint.name == "联影医疗") {
								PushTrackEvent('主馆', '跳转联影');
								setPovit()
								SceneMove("lianying");
							}
							else {
								OpenPicture(jsonPoint);
								PushTrackEvent('点击logo', jsonPoint.name);
								document.getElementsByClassName('bottom-btns-top')[0].style.display = 'none';
								window.isopentop = true;
							}
						}
					}, new Vector3(jsonPoint.pos[0], jsonPoint.pos[1], jsonPoint.pos[2]), new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
						// console.log(jsonPoint.name);
						points.push(imageObject);
					}, true);
				}
			}

			
			let hintPath = window.configJson.IconPaths.hintPanel;
			var hint = OpenNormalPictureWithoutExit(hintPath);
			window.canClick = false;
			window.addEventListener('click', hintClick);
			
			function hintClick() {
				// alert("`11111111");
				console.log(hint);
				window.canClick = true;
				// hint.parentNode.removeChild(hint);
				window.removeEventListener('click', hintClick);
				hint.hide();
			}


			let vrlogo = window.configJson.IconPaths.vrlogo;
			// let pinpaili = window.configJson.VideoPaths.pinpaili;
			window.app.viewer.addImageObject(vrlogo, function () {
				
			}, new Vector3(25.52, -8.8, 39.38), new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				points.push(imageObject);
			}, true);
			window.app.viewer.addImageObject(vrlogo, function () {
				
			}, new Vector3(38.01, -8.0, 28.75), new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				points.push(imageObject);
			}, true);
			window.app.viewer.addImageObject(vrlogo, function () {
				
			}, new Vector3(26.66,-21.5,35.74), new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				points.push(imageObject);
			}, true);
			window.app.viewer.addImageObject(vrlogo, function () {
				
			}, new Vector3(37.43, -23.3, 22.8), new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				points.push(imageObject);
			}, true);


			

			window.renderer.domElement.addEventListener('mousedown', this.downClbk);
			window.renderer.domElement.addEventListener("touchstart", this.downClbk);
			window.renderer.domElement.addEventListener('mouseup', this.upClbk);
			window.renderer.domElement.addEventListener("touchend", this.upClbk);
			window.renderer.domElement.addEventListener("touchcancel", this.upClbk);

			window.renderer.domElement.addEventListener('mousedown', this.onMouseDown, false);
		}

		let self = this;
		this.onMouseDown = function (event) {
			return;
			let mouse = new Vector2()
			let raycaster = new Raycaster();
			// Check mouse
			mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
			mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

			raycaster.setFromCamera(mouse, self.params.camera);
			let pos = raycaster.ray.at(50);
			console.log(pos);

			window.app.viewer.addImageObject('assets/textures/icon.gif', function () {
				console.log('image object is clicked');
			}, pos, new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				// yuewen=imageObject;		
				// owner.mainScenes.push(yuewen);	
			}, true);

		}

		this.end = function()
		{
			console.log('end state: ' + this.name);

			if(points != null & points.length != 0)
			{
				for (let i = 0; i < points.length; i++) {
					const point = points[i];
					point.visible = false;
				}
			}

			let initialFov = JSON.parse(window.sessionStorage.getItem("initial_camera_fov"));
			let initialCameraPosition =JSON.parse( window.sessionStorage.getItem("initial_camera_pos"));
			let initialCameraRot =JSON.parse( window.sessionStorage.getItem("initial_camera_rot"));
			
			// console.log(initialFov);
			// console.log(initialCameraPosition);
			// console.log(initialCameraRot);

			this.owner.showMainScene(true);
			this.params.camera.fov = initialFov;
			this.params.camera.updateProjectionMatrix();

			this.params.camera.position.set(initialCameraPosition.x, initialCameraPosition.y, initialCameraPosition.z);
			this.params.camera.quaternion.set(initialCameraRot._x, initialCameraRot._y, initialCameraRot._z, initialCameraRot._w);

			window.renderer.domElement.removeEventListener('mousedown', this.downClbk);
			window.renderer.domElement.removeEventListener("touchstart", this.downClbk);
			window.renderer.domElement.removeEventListener('mouseup', this.upClbk);
			window.renderer.domElement.removeEventListener("touchend", this.upClbk);
			window.renderer.domElement.removeEventListener("touchcancel", this.upClbk);
		}

		this.update = function (deltaTime) 
		{

		}

		var mouse = new Vector2();
		this.downClbk = function (event) {
			if (event.type == "touchstart") {
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			}
			window.renderer.domElement.addEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.addEventListener("touchmove", self.moveClbk);
		}
		
		this.upClbk = function (e) {
			window.renderer.domElement.removeEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.removeEventListener("touchmove", self.moveClbk);
		}
		
		this.moveClbk = function (event) {
			var movementX = null;
			var movementY = null;
			euler.setFromQuaternion(orbCamera.quaternion);
			if (event.type == "touchmove") {
				movementX = (event.touches[event.touches.length - 1].clientX - mouse.x) * 3 || 0;
				movementY = (event.touches[event.touches.length - 1].clientY - mouse.y) * 3 || 0;
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			} else {
				movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
				movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;
			}
			euler.y -= movementX * 0.002;
			euler.x -= movementY * 0.002;
			euler.x = Math.max(- PI_2, Math.min(PI_2, euler.x));
			orbCamera.quaternion.setFromEuler(euler);
		}



	}

}

class MoveToScreenState {
	constructor(options) 
	{
		this.name = options.name;
		this.params = options.params;
		this.owner = options.owner;

		this.duration = 1000.0;
		this.timer = 0.0;

		this.isOver = false;

		this.positionMoveVector = null;
		this.targetMoveVector = null;

		this.initialCameraPosition = null;
		this.initialCameraTarget = null;
		this.TargetFov = 58;
		this.initialFov = null;

		this.initialCameraRot = null;
		this.targetCameraRot = null;


		this.start = function (args) {
			console.log('start state: ' + this.name);
			this.isOver = false;
			this.timer = 0.0;

			this.initialCameraPosition = new Vector3(this.params.camera.position.x, this.params.camera.position.y, this.params.camera.position.z);
			// this.initialCameraTarget = this.params.camera.getWorldDirection().clone();
			this.initialCameraRot = this.params.camera.quaternion.clone();
			// this.initialCameraRot = this.params.camera.rotation.clone();
			this.initialFov = this.params.camera.fov;
			this.TargetFov = this.initialFov;

			window.sessionStorage.setItem("initial_camera_fov", JSON.stringify(this.initialFov));
			window.sessionStorage.setItem("initial_camera_pos", JSON.stringify(this.initialCameraPosition));
			window.sessionStorage.setItem("initial_camera_rot", JSON.stringify(this.initialCameraRot));

			this.positionMoveVector = new Vector3().subVectors(new Vector3(0, 0, 0), this.params.camera.position);
			// this.targetMoveVector = new Vector3().subVectors(this.screenCameraTarget, args.pos);
			// let x = args.pos.angleTo(new Vector3(1, 0, 0));
			// let y = args.pos.angleTo(new Vector3(0, 1, 0));
			// let z = args.pos.angleTo(new Vector3(0, 0, 1));
			// let rot = new Euler(x, y, z, "XYZ");
			// let rot = new Euler(1.973741, -0.810768, 2.103299, "XYZ");
			let firstPos;
			if(args.targetID)
			{
				firstPos = window.configJson.LogoDetailPoints[args.targetID].pos;
			}else
			{
				firstPos = window.configJson.LogoDetailPoints[0].pos;
			}
			
			this.params.camera.position.set(0, 0, 0);
			this.params.camera.lookAt(new Vector3(firstPos[0], firstPos[1], firstPos[2]));
			
			// let rot = new Euler(-2.82763, -0.47923, -2.9921);
			// let rot = new Euler().setFromQuaternion(qua);
			// this.targetCameraRot = new Quaternion().setFromEuler(rot);
			this.targetCameraRot = this.params.camera.quaternion.clone();
			this.params.camera.position.set(this.initialCameraPosition.x, this.initialCameraPosition.y, this.initialCameraPosition.z);
			this.params.camera.quaternion.set(this.initialCameraRot.x, this.initialCameraRot.y, this.initialCameraRot.z, this.initialCameraRot.w);
			// this.targetCameraRot = rot;
			

			//this.params.camera.fov = this.TargetFov;
			// console.log(rot);


		}

		
		this.end = function () {
			console.log('end state: ' + this.name);
			showWeb = false;

			// window.renderer.domElement.removeEventListener('mousedown', this.onMouseDown, false);

		}

		let showWeb = false;

		
		this.update = function (deltaTime) 
		{
			if (this.isOver) {
				return;
			}

			if (this.timer > this.duration) {
				this.isOver = true;
				this.end();
			}
			else {

				var epsilon = this.timer;

				if (epsilon <= 1) {
					if (epsilon > 0.5) {
						this.owner.showMainScene(false);
					}
					this.params.camera.fov = (this.TargetFov - this.initialFov) * epsilon + this.initialFov;
					this.params.camera.updateProjectionMatrix();

					this.params.camera.position.set(this.initialCameraPosition.x + this.positionMoveVector.x * epsilon,
						this.initialCameraPosition.y + this.positionMoveVector.y * epsilon,
						this.initialCameraPosition.z + this.positionMoveVector.z * epsilon);

					let newQ = new Quaternion();
					Quaternion.slerp(this.initialCameraRot, this.targetCameraRot, newQ, epsilon);

					this.params.camera.quaternion.set(newQ.x, newQ.y, newQ.z, newQ.w);

				}

				if (epsilon > 1) {
					if (showWeb == false) {
						showWeb = true;

						//todo: 进入 StayViewLogo状态
						this.owner.ExcudeCmd("StayViewLogoState");

					}
				}

				this.timer += deltaTime;
			}
			// console.log(this.params.camera.rotation);

		}

	
	}
}
	



class FreeMoveState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;
		this.owner = options.owner;

		this.duration = 1.0;
		this.timer = 0.0;
		this.isOver = false;
		var euler = new Euler(0, 0, 0, 'YXZ');
		var PI_2 = Math.PI / 2;
		var vec = new Vector3();
		var old = new Vector3();
		var orbCamera = this.params.camera;
		var speed = window.configJson.MoveSpeed.tvtower;

		let imageObjects = [];
		this.start = function () {
			console.log('start state: ' + this.name);
			this.CreateJoySystem();
			let owner = this.owner;
			ShowMainIcon();
			if(imageObjects != null && imageObjects.length != 0)
			{
				console.log("imageObjects already exist");
				for (let i = 0; i < imageObjects.length; i++) {
					const obj = imageObjects[i];
					obj.visible = true;
				}
			}
			else
			{
				console.log("new imageObject");				
				// window.app.viewer.addImageObject(window.configJson.IconPaths.guanggappai, function () {
				
				// }, new Vector3(32,30,12), new Vector3(1, 1, 1), new Vector3(0, 0, 0), function (imageObject) {
				// 	owner.mainScenes.push(imageObject);	
				// 	imageObjects.push(imageObject);
				// }, true); 
	
				//品牌视频
				let whiteVideoPaht = window.configJson.IconPaths.whiteVideo;
				let pinpaili = window.configJson.VideoPaths.pinpaili;
				window.app.viewer.addImageObject(whiteVideoPaht, function () {					
					lightbox_open(pinpaili);
					PushTrackEvent('主馆','打开品牌视频');
				}, new Vector3(32, 30, 12), new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {				
					owner.mainScenes.push(video);	
					imageObjects.push(imageObject);	
				}, true);
				//回顾视频
				let huigu = window.configJson.VideoPaths.huigu;
				window.app.viewer.addImageObject(whiteVideoPaht, function () {
					lightbox_open(huigu);
					PushTrackEvent('主馆','打开回顾视频');
				}, new Vector3(-23, 25, 12), new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {				
					owner.mainScenes.push(video);	
					imageObjects.push(imageObject);	
				}, true);
				//抗疫视频
				let kangyi = window.configJson.VideoPaths.kangyi;
				window.app.viewer.addImageObject(whiteVideoPaht, function () {
					lightbox_open(kangyi);
					PushTrackEvent('主馆','打开抗议视频');
				}, new Vector3(15, 30, 30), new Vector3(5, 5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {				
					owner.mainScenes.push(video);	
					imageObjects.push(imageObject);	
				}, true);

				let canguanPath = window.configJson.IconPaths.jixucanguan;
				window.app.viewer.addImageObject(canguanPath, function () {
					console.log("click");
					if(owner)
					{
						PushTrackEvent('主馆','访问logo墙');
						owner.ExcudeCmd("MoveToScreenState",{pos:new Vector3(32, 30, 12), targetID: 9});
						HideMainIcon();
					}
				}, new Vector3(20, 5, 10), new Vector3(20, 10, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
					moveToFocus=imageObject;	
					owner.mainScenes.push(moveToFocus);		
					imageObjects.push(imageObject);
				}, true);

				var paiziPaths = window.configJson.IconPaths.canzhantaimingdan
				let yunzhaolanPath = window.configJson.IconPaths.yunzhanlanpingpai;
				window.app.viewer.addImageObject(yunzhaolanPath, function () {
					OpenPictureWithUrl(window.configJson.IconPaths.canzhantaimingdan);

					
				}, new Vector3(-30, 5, 10), new Vector3(20, 10, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
					owner.mainScenes.push(imageObject);
					imageObjects.push(imageObject);
				}, true);

				//品牌直播间
				let zhiboPath = window.configJson.IconPaths.zhibojian;	
				let zhibovideo = window.configJson.VideoPaths.pinapizhibojian;	
				window.app.viewer.addImageObject(zhiboPath, function () {
					// OpenNormalPicture(zhibojianpicPath);
					lightbox_open(zhibovideo);
					PushTrackEvent('主馆','打开品牌直播间');
				}, new Vector3(0, zhiboY, -7), new Vector3(10, 10, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
					zhibojian = imageObject;
					owner.mainScenes.push(imageObject);
					imageObjects.push(imageObject);
				}, false);
				
				//上海云展概览
				var guanggaopai = window.configJson.IconPaths.guanggappai;
				window.app.viewer.addImageObject(guanggaopai, function () {
					let path = window.configJson.IconPaths.shanghaiguanIntro;
					var hint = OpenNormalPicture(path);
				}, new Vector3(0.25,5.3,-5), new Vector3(8, 8, 1), new Vector3(0, 0, 0), function (imageObject) {
					// this.owner.airShip = imageObject;
					imageObjects.push(imageObject);
				}, true); 

				//品牌列表
				let paipianIcon = window.configJson.IconPaths.paipianIcon;
				window.app.viewer.addImageObject(paipianIcon, function () {
					let path = window.configJson.IconPaths.zhibojianpic;
					var hint = OpenNormalPicture(path);
					PushTrackEvent('主馆','打开品牌排片表');
				}, new Vector3(0.25,10,-7), new Vector3(10, 5, 1), new Vector3(0, 0, 0), function (imageObject) {
					// this.owner.airShip = imageObject;
					imageObjects.push(imageObject);
				}, true); 
				
				let bgmPath = window.configJson.AudioPath.zhuguanbgm;
				let pangbaiPath = window.configJson.AudioPath.zhuguanpangbai;
				window.app.CreateAudioPlayerList(bgmPath, pangbaiPath,"jingying-main","shengying-main");
				
			}			
			
		}

		this.CreateJoySystem = function()
		{
			var joy = document.getElementById("nipple_0_0");
			if(joy != null)
			{
				console.log("joy already exist");
				joy.style.display = 'block';
			}
			else
			{
				console.log("create new joy");
				joy = new Joystick({ zone: document.getElementById("left") }).init();
			}


			// var joy = new Joystick({ zone: document.getElementById("left") }).init();
			joy.onStart = function (distance, angle) {
				if (distance < 20) {
					return;
				}
				old.copy(orbCamera.position);
				let val = distance * delTime * speed;
				switch (angle) {
					case 'up':
						self.moveForward(val);
						break;
					case 'right':
						self.moveRight(val);
						break;
					case 'left':
						self.moveLeft(val);
						break;
					case 'down':
						self.moveBack(val);
						break;
				};
			}
			window.renderer.domElement.addEventListener('mousedown', this.downClbk);
			window.renderer.domElement.addEventListener("touchstart", this.downClbk);
			window.renderer.domElement.addEventListener('mouseup', this.upClbk);
			window.renderer.domElement.addEventListener("touchend", this.upClbk);
			window.renderer.domElement.addEventListener("touchcancel", this.upClbk);
			this.owner.airShip.visible=true;
		}

		var whiltePath = window.configJson.IconPaths.whiteCircle;
		

		this.end = function () {
			let joy = document.getElementById("nipple_0_0");
			joy.style.display = 'none';
			console.log('end state: ' + this.name);

			window.renderer.domElement.removeEventListener('mousedown', this.downClbk);
			window.renderer.domElement.removeEventListener("touchstart", this.downClbk);
			window.renderer.domElement.removeEventListener('mouseup', this.upClbk);
			window.renderer.domElement.removeEventListener("touchend", this.upClbk);
			window.renderer.domElement.removeEventListener("touchcancel", this.upClbk);

			if(imageObjects != null && imageObjects.length != 0)
			{
				for (let i = 0; i < imageObjects.length; i++) {
					const obj = imageObjects[i];
					obj.visible = false;
				}
			}
		}

		var self = this;
		var mouse = new Vector2();
		this.raycaster = new Raycaster();
		var yuewen=null;
		var vangogh=null;
		var lianying=null;
		var game=null;
		var dabaitu=null;
		var video=null;
		var moveToFocus = null;
		var mouseTarget = new Vector2();
		var zhibojian=null
		var center=new Vector3(0,15,0);
		
		
		this.downClbk = function (event) {
			if (event.type == "touchstart") {
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			}
			window.renderer.domElement.addEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.addEventListener("touchmove", self.moveClbk);
		}

		this.upClbk = function (e) {
			window.renderer.domElement.removeEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.removeEventListener("touchmove", self.moveClbk);
		}

		this.moveClbk = function (event) {
			var movementX = null;
			var movementY = null;
			euler.setFromQuaternion(orbCamera.quaternion);
			if (event.type == "touchmove") {
				movementX = (event.touches[event.touches.length - 1].clientX - mouse.x) * 3 || 0;
				movementY = (event.touches[event.touches.length - 1].clientY - mouse.y) * 3 || 0;
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			} else {
				movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
				movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;
			}
			euler.y -= movementX * 0.002;
			euler.x -= movementY * 0.002;
			euler.x = Math.max(- PI_2, Math.min(PI_2, euler.x));
			orbCamera.quaternion.setFromEuler(euler);
		}

		this.checkDisValLeagel = function (vec, distance) {
			let nextPos = new Vector3();
			let normalizedVec = new Vector3();
			normalizedVec.copy(vec);
			normalizedVec.normalize();
			nextPos.copy(orbCamera.position);
			nextPos.addScaledVector(normalizedVec, distance);
			let length = nextPos.length();
			if (length < 48 && length > 14) {
				return { vec: normalizedVec, distance: distance };
			} else if (length < 14) {
				//var newVec = new Vector3();
				var temp = new Vector3();
				var toCenterDir = new Vector3();
				toCenterDir.setX(-orbCamera.position.x);
				toCenterDir.setZ(-orbCamera.position.z);
				toCenterDir.normalize();

				let toCenterVal = vec.dot(toCenterDir);
				toCenterDir.x = toCenterDir.x * toCenterVal;
				toCenterDir.y = toCenterDir.y * toCenterVal;
				toCenterDir.z = toCenterDir.z * toCenterVal;
				vec.sub(toCenterDir);
				var d = vec.length();
				return { vec: vec, distance: d };
			}
			else {
				return { vec: new Vector3(), distance: 0 };
			}
		}
		this.outputVec = function (name, vec) {
			console.log(name + ": " + vec.x + "," + vec.y + "," + vec.z);
		}

		this.moveForward = function (distance) {
			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			//获取四元数中x项
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			//与up叉乘算出forward;
			vec.crossVectors(orbCamera.up, vec);

			let opts = this.checkDisValLeagel(vec, distance);
			// if (true) {
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
			// }
		};

		this.moveBack = function (distance) {
			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			//获取四元数中x项
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			//与up叉乘算出forward;
			vec.setX(-vec.x);
			vec.setY(-vec.y);
			vec.setZ(-vec.z);
			vec.crossVectors(orbCamera.up, vec);

			let opts = this.checkDisValLeagel(vec, distance);
			// if (true) {
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
			// }
		};

		this.moveRight = function (distance) {
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.moveLeft = function (distance) {
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			vec.setX(-vec.x);
			vec.setY(-vec.y);
			vec.setZ(-vec.z);
			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};
		var zhiboY = 17;
		//设置直播间logo跟随玩家视角移动
		this.SetLogoPositon = function () {
			// console.log(zhibojian.position);
			// // console.log(self.params.camera.position);		
			var dir = new Vector3().copy(self.params.camera.position).sub(center).setY(0);
			// // var mag=5;					
			dir.normalize().multiplyScalar(7);
			// console.log(dir);	

			var targetPos = dir.clone().add(center);
			zhibojian.position.set(targetPos.x, zhiboY, targetPos.z);
			// console.log(zhibojian.position);
			zhibojian.lookAt(new Vector3(self.params.camera.position.x, zhiboY, self.params.camera.position.z));
			// console.log(zhibojian.rotation);

		};

		var delTime = 0.02;
		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}
			this.SetLogoPositon();
			// this, this.outputVec("now pos: ", orbCamera.position);
			// delTime = deltaTime;
			
		}
	}
}

class ShineIndicatorState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;

		this.duration = 3.0;
		this.timer = 0.0;

		this.isOver = false;

		this.heartIndicatorScale = null;

		this.vangoghIndicatorScale = null;

		this.raycaster = new Raycaster();
		this.mouse = new Vector2();

		this.isHeartClicked = false;

		this.isVangoghClicked = false;

		this.start = function () {
			console.log('start state: ' + this.name);

			console.log(this.params.utils);

			// this.params.controls.enableRotate = true;

			this.heartIndicatorScale = new Vector3(this.params.utils.heartIndicator.scale.x, this.params.utils.heartIndicator.scale.y, this.params.utils.heartIndicator.scale.z);
			this.params.utils.heartIndicator.visible = true;

			this.vangoghIndicatorScale = new Vector3(this.params.utils.vangoghIndicator.scale.x, this.params.utils.vangoghIndicator.scale.y, this.params.utils.vangoghIndicator.scale.z);
			this.params.utils.vangoghIndicator.visible = true;

			window.renderer.domElement.addEventListener('mousedown', this.onMouseOrTouchDown, false);
			window.renderer.domElement.addEventListener("touchstart", this.onMouseOrTouchDown, false);
		}

		var self = this;
		this.onMouseOrTouchDown = function (event) {
			console.log('onMouseMove');

			// Check mouse
			self.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
			self.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

			// Check touch
			if (event.touches && event.touches.length == 1) {
				self.mouse.x = (event.touches[0].pageX / window.innerWidth) * 2 - 1;
				self.mouse.y = - (event.touches[0].pageY / window.innerHeight) * 2 + 1;
			}

			self.raycaster.setFromCamera(self.mouse, self.params.camera);

			var intersects = null;

			intersects = self.raycaster.intersectObject(self.params.utils.heartIndicator);
			if (intersects.length > 0) {
				self.isHeartClicked = true;
			}

			intersects = self.raycaster.intersectObject(self.params.utils.vangoghIndicator);
			if (intersects.length > 0) {
				self.isVangoghClicked = true;
			}
		}


		// this.onTouchStart = function(event) 
		// {
		// 	console.log('onTouchEnd');

		// 	console.log(event);
		// 	if ( event.touches.length == 1 ) 
		// 	{
		// 		self.mouse.x = ( event.touches[0].pageX / window.innerWidth ) * 2 - 1;
		// 		self.mouse.y = - ( event.touches[0].pageY / window.innerHeight ) * 2 + 1;

		// 		self.raycaster.setFromCamera(self.mouse, self.params.camera);
		// 		var intersects = self.raycaster.intersectObject(self.params.utils.heartIndicator);

		// 		if (intersects.length > 0)
		// 		{
		// 			self.isHeartClicked = true;
		// 		}
		// 	}

		// }

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}

			if (this.isHeartClicked || this.isVangoghClicked) {
				this.isOver = true;

				this.end();

				if (this.isVangoghClicked) {
					console.log(window.location);
					window.open(window.location.href + "?scene=vangogh", '_self', false);
				}
			}
			else {
				var epsilon = Math.sin(this.timer * 10) * 0.2 + 1.0;
				this.params.utils.heartIndicator.scale.x = this.heartIndicatorScale.x * epsilon;
				this.params.utils.heartIndicator.scale.y = this.heartIndicatorScale.y * epsilon;
				this.params.utils.heartIndicator.scale.z = this.heartIndicatorScale.z * epsilon;

				this.params.utils.vangoghIndicator.scale.x = this.vangoghIndicatorScale.x * epsilon;
				this.params.utils.vangoghIndicator.scale.y = this.vangoghIndicatorScale.y * epsilon;
				this.params.utils.vangoghIndicator.scale.z = this.vangoghIndicatorScale.z * epsilon;
			}

			this.timer += deltaTime;
		}
	}
}

class ClickToPlayGameState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;

		this.duration = 3.0;
		this.timer = 0.0;

		this.isOver = false;

		this.heartIndicatorScale = null;

		this.raycaster = new Raycaster();
		this.mouse = new Vector2();

		this.isHeartClicked = false;
		this.start = function () {
			console.log('start state: ' + this.name);

			console.log(this.params.utils);

			this.heartIndicatorScale = new Vector3(this.params.utils.heartIndicator.scale.x, this.params.utils.heartIndicator.scale.y, this.params.utils.heartIndicator.scale.z);
			this.params.utils.heartIndicator.visible = true;

			this.params.utils.heartIndicator.material.emissive = new Color(0xff0000);

			this.params.utils.heartIndicator.rotation.y = -0.4;

			window.renderer.domElement.addEventListener('mousedown', this.onMouseDown, false);
			window.renderer.domElement.addEventListener("touchstart", this.onTouchStart);

		}

		var self = this;
		this.onMouseDown = function (event) {
			self.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
			self.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

			self.raycaster.setFromCamera(self.mouse, self.params.camera);
			var intersects = self.raycaster.intersectObject(self.params.utils.heartIndicator);

			if (intersects.length > 0) {
				self.isHeartClicked = true;
			}
		}
		this.onTouchStart = function (event) {
			if (event.touches.length == 1) {
				self.mouse.x = (event.touches[0].pageX / window.innerWidth) * 2 - 1;
				self.mouse.y = - (event.touches[0].pageY / window.innerHeight) * 2 + 1;

				self.raycaster.setFromCamera(self.mouse, self.params.camera);
				var intersects = self.raycaster.intersectObject(self.params.utils.heartIndicator);

				if (intersects.length > 0) {
					self.isHeartClicked = true;
				}
			}
		}

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}

			if (this.isHeartClicked) {
				this.isOver = true;

				this.end();

				window.open(window.configJson.GameYa.Url, '_self', false);
			}
			else {
				var epsilon = Math.sin(this.timer * 20) * 0.2 + 1.5;
				this.params.utils.heartIndicator.scale.x = this.heartIndicatorScale.x * epsilon;
				this.params.utils.heartIndicator.scale.y = this.heartIndicatorScale.y * epsilon;
				this.params.utils.heartIndicator.scale.z = this.heartIndicatorScale.z * epsilon;

			}

			this.timer += deltaTime;
		}
	}
}

export class StateMachine {
	constructor(options) {
		this.states = [];
		this.activeState = -1;
		this.isStarted = false;
		this.options = options;

		this.liveScreen = {};

		this.liveSkybox = {};

		this.liveLogo = {};

		this.mainScenes = [];

		/**
		 * 宇宙飞船
		 */
		this.airShip = null;
		this.lastCameraPosition = null;
		this.mainScenesActive = true;
		var self = this;
		this.showMainScene = function (isShow) {
			// console.log("showMainScene isShow = " + isShow);
			if (isShow == this.mainScenesActive) {
				return;
			}
			// console.log("showMainScene enter");
			this.mainScenesActive = isShow;
			for (var i = 0; i < this.mainScenes.length; ++i) {
				if(this.mainScenes[i])
				{

					this.mainScenes[i].visible = isShow;
				}
			}
		}

		this.lookAtTargetPoint = function (targetPoint) {
			this.lastCameraPosition = new Vector3(this.options.camera.position.x, this.options.camera.position.y, this.options.camera.position.z);

			var viewDir = new Vector3().subVectors(this.options.controls.target, targetPoint);
			viewDir.normalize();

			var distance = 5;

			this.options.camera.position.x = this.options.controls.target.x + distance * viewDir.x;
			this.options.camera.position.y = this.options.controls.target.y + distance * viewDir.y;
			this.options.camera.position.z = this.options.controls.target.z + distance * viewDir.z;
		}

		this.recoverLastCameraPosition = function () {
			if (this.lastCameraPosition) {
				this.options.camera.position.x = this.lastCameraPosition.x;
				this.options.camera.position.y = this.lastCameraPosition.y;
				this.options.camera.position.z = this.lastCameraPosition.z;

				this.lastCameraPosition = null;
			}
		}

		this.start = function () {
			this.isStarted = true;

			this.states[this.activeState].start();

			/*
			//添加图片对象的示例调用
			//this.options.viewer.addImageObject('assets/environment/test.png', function()
			this.options.viewer.addImageObject('assets/textures/red.gif', function()
			{
				console.log('image object is clicked');
			}, new Vector3(10.0, 0.0, 0.0), new Vector3(10, 10, 1), new Vector3(0,Math.PI,0), function(imageObject)
			{
				console.log('===============get the object');
			}, true);
			//*/
		}

		this.beginToShineLogo = function (targetMateril) {
			this.liveLogo.mtl = targetMateril;
			this.liveLogo.maps = [];

			var sm = this;
			for (var i = 1; i <= 5; ++i) {
				new TextureLoader().load('assets/textures/logo_Wall/Wall_logo_flow0' + i + 'a.png', (texture) => {
					texture.flipY = false;
					texture.encoding = sRGBEncoding;
					sm.liveLogo.maps.push(texture);

					if (sm.liveLogo.maps.length == 5) {
						this.liveLogo.liveCounter = 0;
					}

				}, null, null);
			}
		}

		this.updateLiveLogo = function (deltaTime) {
			if (this.liveLogo.liveCounter != undefined) {
				this.liveLogo.liveCounter += deltaTime;

				var refreshInterval = 1.0;
				if (this.liveLogo.liveCounter > refreshInterval) {
					this.liveLogo.liveCounter = 0.0;
					function getRandomIntInclusive(min, max) {
						min = Math.ceil(min);
						max = Math.floor(max);
						return Math.floor(Math.random() * (max - min + 1)) + min;
					}

					var logoIndex = getRandomIntInclusive(0, this.liveLogo.maps.length - 1);

					this.liveLogo.mtl.map = this.liveLogo.maps[logoIndex];
					this.liveLogo.mtl.needsUpdate = true;
				}

			}
		}

		this.loadSkyboxHD = function(targetMaterial)
		{
			this.liveSkybox.mtlHD = targetMaterial;

			var sm = this;
			new TextureLoader().load("assets/environment/skybox.jpg", ( texture ) => {
				sm.liveSkybox.mtlHD.map = texture;
				sm.liveSkybox.needsUpdate = true;
				
			  }, null, null);
		}

		this.beginToShineSkybox = function (targetMaterial) {
			this.liveSkybox.mtl = targetMaterial;
			this.liveSkybox.maps = {};
			this.liveSkybox.maps[0] = targetMaterial.map;

			var sm = this;
			for (var i = 2; i <= 5; ++i) {
				new TextureLoader().load('assets/textures/logo_Skybox/logo' + i + '.png', ( texture ) => {
					//texture.flipY = false;
					//texture.encoding = sRGBEncoding;
					texture.repeat.y = 2;
					texture.offset.y = -0.285;
					texture.offset.x = 0.0005;
					texture.needsUpdate = true;

					var id = texture.image.src.lastIndexOf('/');
					var idstr = texture.image.src.substring(id + 5, id + 6);
					var index = parseInt(idstr) - 1;

					switch(index)
					{
						case 1:

						break;

						case 2:
						{
							texture.offset.y -= 0.0004;
							texture.offset.x += 0.00001;
							texture.needsUpdate = true;
						}
						
						break;

						case 3:
						{
							texture.offset.y -= 0.0;
							texture.offset.x -= 0.00001;
							texture.needsUpdate = true;
						}
						break;

						case 4:
						{
							texture.offset.y -= 0.0004;
							texture.offset.x -= 0.00001;
							texture.needsUpdate = true;
						}
						break;
					}

					sm.liveSkybox.maps[index] = texture;

					if (Object.keys(sm.liveSkybox.maps).length == 5)
					{
						this.liveSkybox.liveCounter = 0;
					}

				}, null, null);
			}
		}

		this.updateLiveSkybox = function (deltaTime) {
			if (this.liveSkybox.liveCounter != undefined) {
				this.liveSkybox.liveCounter += deltaTime;

				var refreshInterval = 0.8;
				if (this.liveSkybox.liveCounter > refreshInterval) {
					this.liveSkybox.liveCounter = 0.0;

					function getRandomIntInclusive(min, max) {
						min = Math.ceil(min);
						max = Math.floor(max);
						return Math.floor(Math.random() * (max - min + 1)) + min;
					}

					var logoIndex = getRandomIntInclusive(0, 4);

					this.liveSkybox.mtl.map = this.liveSkybox.maps[logoIndex];
					this.liveSkybox.mtl.needsUpdate = true;
				}



			}
		}

		this.beginToLive = function (targetMaterial) {
			this.liveScreen.mtl = targetMaterial;
			this.liveScreen.maps = [];
			this.liveScreen.maps.push(targetMaterial.emissiveMap);

			var sm = this;
			for (var i = 2; i <= 4; ++i) {
				new TextureLoader().load('assets/textures/ygj_00' + i + '.jpg', (texture) => {
					texture.flipY = false;
					texture.encoding = sRGBEncoding;
					sm.liveScreen.maps.push(texture);

					if (sm.liveScreen.maps.length == 4) {
						this.liveScreen.liveCounter = 1;
					}

				}, null, null);
			}
		}

		this.updateLiveScreen = function (deltaTime) {
			if (this.liveScreen.liveCounter) {
				var fps = 3;
				this.liveScreen.liveCounter += (deltaTime * fps);

				var index = Math.round(this.liveScreen.liveCounter % 3);

				this.liveScreen.mtl.emissiveMap = this.liveScreen.maps[index];
				this.liveScreen.mtl.needsUpdate = true;
			}
		}

		this.initializeStates = function () {
			this.activeState = 0;
			console.log("StateMachine Main is Start");
			this.states.push(new StartState({ name: 'StartState', params: this.options , owner: this}));
			this.states.push(new FreeMoveState({ name: 'FreeMoveState', params: this.options, owner: this }));
			this.states.push(new ShineIndicatorState({ name: 'ShineIndicatorState', params: this.options }));
			this.states.push(new MoveToScreenState({ name: 'MoveToScreenState', params: this.options, owner: this }));
			this.states.push(new ClickToPlayGameState({ name: 'ClickToPlayGameState', params: this.options }));
			this.states.push(new InteractWithLogoState({ name: 'InteractWithLogoState', params: this.options }));
			this.states.push(new StayViewLogoState({ name: 'StayViewLogoState', params: this.options, owner: this }))

			let airshipPath = window.configJson.IconPaths.airship;
			this.options.viewer.addImageObject(airshipPath, function () {

			}, new Vector3(-32, 15, 48), new Vector3(40, 20, 20), new Vector3(0, 0, 0), function (imageObject) {
				self.airShip = imageObject;
				imageObject.visible=false;
			}, true);

		}

		this.update = function (deltaTime) {
			if (this.isStarted == false) {
				return;
			}

			if (this.activeState >= 0 && this.activeState < this.states.length) {
				this.states[this.activeState].update(deltaTime);

				if (this.states[this.activeState].isOver) {
					// Jump to next
					this.activeState++;
					console.log("now state is " + this.states[this.activeState].name);
					if (this.activeState < this.states.length) {
						this.states[this.activeState].start();
					}
				}
			}

			this.updateLiveScreen(deltaTime);

			this.updateLiveSkybox(deltaTime);

			this.updateLiveLogo(deltaTime);
		}

		this.initializeStates();
	}

	ExcudeCmd(cmd, args) {
		// if (cmd == "ShineIndicatorState") {
		// 	this.states[this.activeState].end();
		// 	for (let index = 0; index < this.states.length; index++) {
		// 		const element = this.states[index];
		// 		if (element.name == cmd) {
		// 			this.states[element].start();
		// 			break;
		// 		}
		// 	}
		// }
		for (let index = 0; index < this.states.length; index++) {
			const element = this.states[index];
			if (element.name == cmd) {
				this.states[this.activeState].end();
				this.activeState = index;
				this.states[this.activeState].start(args);
				break;
			}
		}


	}

}
