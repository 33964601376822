import { Viewer } from './viewer.js';
import { Vector3, Raycaster, Vector2, Euler, CubicBezierCurve3, Geometry, LineBasicMaterial, Line, Ray, AudioListener, Audio, AudioLoader } from 'three';
import { Joystick } from "./joystick.js";

class StartState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;

		this.duration = 2.0;
		this.timer = 0.0;

		this.isOver = false;

		this.start = function () {
			console.log('start state: ' + this.name);
			// let path = window.configJson.VideoPaths.yuewen;
			// lightbox_open(path);
			PushTrackEvent('阅文','访问');
			
		}

		this.end = function () {
			console.log('end state: ' + this.name);
			window.app.ShowHint(window.configJson.SceneIntro.yuewen);
		}

		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}
			// this.timer >= this.duration
			// window.app.viewer.isPlayVideoFinish

			if (this.timer >= this.duration) {
				this.isOver = true;
				let jsonvalue1 = window.configJson.IconPaths.yuewenshuoming;
				let jsonvalue2 = window.configJson.IconPaths.yuewenfenxiang;
				let urlstring = window.configJson.GameYa.qidian;
				let alt = "yuewen";
				ShowAndChangePictureTopEX(jsonvalue1, jsonvalue2,true,urlstring,alt);


				this.end();
			}
			else {

			}

			this.timer += deltaTime;
		}
	}
}

class FreeMoveState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;
		this.navMesh = options.navMesh;
		this.machine = options.machine;
		this.json = null;
		this.sphList = [];
		this.jump = false;
		this.groundID = 0;
		this.isOver = false;
		this.jumpDir = null;
		this.targetList = [];
		this.testSphere = [];
		this.enterSphList = [];
		this.exitSphList = [];
		this.douluo_lianjie = null;
		this.douluo_shipin = null;
		this.mouse = new Vector2();
		this.rayCaster = new Raycaster();
		var speed = window.configJson.MoveSpeed.yuewen;
		this.jumpSpeed = window.configJson.JumpSpeed.default;

		this.start = function () {
			console.log('start state: ' + this.name);

			// self.CreatePlatformEnterAndExitPoints();
			self.CreateImageObject();
			this.InitjoyStick();
			this.CreateAudioPlayerList();
		}
		var euler = new Euler(0, 0, 0, 'YXZ');
		var PI_2 = Math.PI / 2;
		var vec = new Vector3();
		var old = new Vector3();
		var orbCamera = this.params.camera;

		this.InitjoyStick = function () {
			var joy = new Joystick({ zone: document.getElementById("left") }).init();
			joy.onStart = function (distance, angle) {
				if (self.jump) {
					return;
				}
				old.copy(orbCamera.position);
				let val = distance * delTime * speed;
				switch (angle) {
					case 'up':
						self.moveForward(val);
						break;
					case 'right':
						self.moveRight(val);
						break;
					case 'left':
						self.moveLeft(val);
						break;
					case 'down':
						self.moveBack(val);
						break;
				};
			}
			window.renderer.domElement.addEventListener('mousedown', this.downClbk);
			window.renderer.domElement.addEventListener("touchstart", this.downClbk);
			window.renderer.domElement.addEventListener('mouseup', this.upClbk);
			window.renderer.domElement.addEventListener("touchend", this.upClbk);
			window.renderer.domElement.addEventListener("touchcancel", this.upClbk);

            let jsonPoints = window.configJson.LogoDetailPoints;
			const jsonPoint = jsonPoints[9];
			let path = window.configJson.IconPaths.pinpai_intro;
			window.app.viewer.addImageObject(path, function () {
				if (GetOpenState() == false) {
					OpenPicture(jsonPoint);
					PushTrackEvent('阅文','查看品牌介绍');
					document.getElementsByClassName('bottom-btns-top-ex')[0].style.display = 'none';
					window.isopentopex=true;
				}

			}, new Vector3(-3.3,-8.5, -10.5), new Vector3(1, 0.5, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				
			}, true);


		}
		var bgmAudio=null;
		var clickAudio = null;
		var pangbaiAudio = null;
		var nowVolume = 0.5;
		this.CreateAudioPlayerList=function()
		{
			let bgmPath = window.configJson.AudioPath.yuewenbgm;
			bgmAudio = window.app.CreateBgmAudioPlayer(bgmPath);
			let clickPath = window.configJson.AudioPath.yuewentiaoyue;
			clickAudio = window.app.CreateClickAudioPlayer(clickPath);
			let pangbaiPath = window.configJson.AudioPath.yuewenpangbai;
			pangbaiAudio = window.app.CreatepPangbaiAudioPlayer(pangbaiPath);

			let muteBtn = GetMutedBtnElement("jingying");
			muteBtn.onclick = function () {
				// if(bgmAudio.getVolume()==0)
				// {
				// 	nowVolume = 0.5;
				// }else{
				// 	nowVolume = 0;
				// }
				window.isMute = false;
				muteBtn.style.display="none";
				voiceBtn.style.display="block";
				nowVolume = 0.5;
				bgmAudio.setVolume(nowVolume);
				clickAudio.setVolume(nowVolume);
				pangbaiAudio.setVolume(nowVolume);
			}
			let voiceBtn = GetVoiceBtnElement("shengying");
			voiceBtn.onclick = function () {
				
				nowVolume = 0;
				window.isMute = true;
				muteBtn.style.display="block";
				voiceBtn.style.display="none";
				bgmAudio.setVolume(nowVolume);
				clickAudio.setVolume(nowVolume);
				pangbaiAudio.setVolume(nowVolume);
			}
		}

		this.LoadJsonFile = function () {
			var configJson = "assets/wenku_platform.json";
			var request = new XMLHttpRequest();
			request.open("get", configJson);
			request.send(null);
			request.onload = function () {
				if (request.status == 200) {
					var json = JSON.parse(request.responseText);
					self.json = json;
				}
			}
		}

		this.LoadJsonFile();
		this.RayrayCaster = function () {
			if (self.jump) {
				return;
			}
			self.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
			self.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

			self.rayCaster.setFromCamera(self.mouse, orbCamera);
			self.platformList.forEach(element => {
				var intersects = self.rayCaster.intersectObject(element.enter);
				if (intersects.length > 0) {
					self.CheckotLeagal(intersects);
				}

				var intersects = self.rayCaster.intersectObject(element.exit);
				if (intersects.length > 0) {
					self.CheckotLeagal(intersects);
				}
			});
			// self.exitSphList.forEach(element => {
			// 	var intersects = self.rayCaster.intersectObject(element);
			// 	if (intersects.length > 0) {
			// 		self.CheckotLeagal(intersects);
			// 	}
			// });
		}

		this.JumpToTarget = function (element, isToNext) {
			let mid = new Vector3().addVectors(orbCamera.position, element).multiplyScalar(0.5);
			let fin = new Vector3().copy(element);
			// let YOffset = isToNext ? (fin.y + 3) : (orbCamera.position.y + 3);
			mid.setY(mid.y + 3);//YOffset);
			fin.setY(fin.y + 0.5);
			self.jumpDir = new Vector3().subVectors(mid, orbCamera.position).normalize();
			//切换到跳跃状态。	
			self.jump = true;
			self.targetList = [];
			self.targetList.push(mid);
			self.targetList.push(fin);
			clickAudio.play();
		}

		this.CheckotLeagal = function (intersects) {
			let pot = new Vector3();
			let plat = self.getNavMeshWithID(self.groundID);
			if (plat == undefined) {
				console.log("the platform id " + self.groundID + " is illeagal")
				return;
			}
			let nextPot = self.machine.exitPoints[self.loadGroundIDInJson()];
			if (nextPot != undefined) {
				pot.copy(nextPot);
				let len = new Vector3().subVectors(intersects[0].object.position, pot).length();
				if (len < 0.01) {
					console.log("Jump to next enter pot");
					let next = self.getNextTargetEnterPos();
					if (next != null) {
						this.JumpToTarget(next, true);
						self.LightBookState();
						self.SetNowPlatPotAppear(false);
						self.groundID++;
						self.SetNowPlatPotAppear(true);

						return;
					}
				}
			}
			nextPot = self.machine.enterPoints[self.loadGroundIDInJson()];
			if (nextPot != undefined) {
				pot.copy(nextPot);
				let len = new Vector3().subVectors(intersects[0].object.position, pot).length();
				if (len < 0.01) {
					console.log("Jump to prev exit pot");
					let next = self.getPrevTargetExitPos();
					if (next != null) {
						this.JumpToTarget(next, false);
						self.LightBookState();
						self.SetNowPlatPotAppear(false);
						self.groundID--;
						self.SetNowPlatPotAppear(true);
						return;
					}
				}
			}
			console.log("You clicked point is not now platforms");
		}


		this.getNextTargetEnterPos = function () {
			let nextid = self.groundID + 1;
			let target = this.getPlatformWithIDInJson(nextid);
			if (target == undefined) {
				return undefined;
			}

			return self.machine.enterPoints[target];
		}

		this.SetNowPlatPotAppear = function (isOn) {
			let target = self.platformList[self.groundID];
			if (target == undefined) {
				return undefined;
			}

			let result = target.enter;
			if (result) {
				result.visible = isOn;
			}

			if (self.groundID == 0) {
				result.visible = false;
			}

			result = target.exit;
			if (result) {
				result.visible = isOn;
			}

			if (self.groundID == self.platformList.length - 1) {
				result.visible = false;
			}

			if (self.groundID == 2)
			{
				this.douluo_lianjie.visible = isOn;
				this.douluo_shipin.visible = isOn;
			}
		}


		this.getPrevTargetExitPos = function () {
			let id = self.groundID - 1;
			let target = this.getPlatformWithIDInJson(id);
			if (target == undefined) {
				return undefined;
			}


			return self.machine.exitPoints[target];
		}

		this.getNavMeshWithID = function (index) {
			if (index >= self.json.platforms.length) {
				return undefined;
			}
			let target = self.json.platforms[index][0];
			let result = self.navMesh[target];
			return result;
		}

		/**
		 * 
		 */
		this.getPlatformWithIDInJson = function (index) {
			if (index >= self.json.platforms.length) {
				return undefined;
			}
			let target = self.json.platforms[index][0];
			return target;
		}

		this.getBookWithIDInJson = function (index) {
			if (index >= self.json.platforms.length) {
				return undefined;
			}
			let target = self.json.platforms[index][1];
			return target;
		}

		this.loadGroundIDInJson = function () {
			return this.getPlatformWithIDInJson(self.groundID);
		}

		this.platformList = [];
		this.CreatePlatformEnterAndExitPoints = function () {

			for (let i = 0; i < self.json.platforms.length; i++) {
				const element = self.json.platforms[i];
				let id = element[0];
				const enter = self.machine.enterPoints[id];
				const exit = self.machine.exitPoints[id];
				let obj = { id: id, enter: enter, exit: exit };

				if (enter != undefined) {
					enter.setY(enter.y + 0.5);
					let sph = window.app.CreateSphereWithScale(enter, 0.01, 0x00ff00);
					obj.enter = sph;
					sph.visible = false;
				}
				if (exit != undefined) {
					exit.setY(exit.y + 0.5);
					let sph = window.app.CreateSphereWithScale(exit, 0.01, 0xff0000);
					obj.exit = sph;
					sph.visible = false;
				}
				self.platformList.push(obj);
			}
			self.platformList[0].exit.visible = true;
			
		}

		var urlIcon = null;
		this.CreateImageObject = function () {
			for (let i = 0; i < self.json.platforms.length; i++) {
				const element = self.json.platforms[i];
				let id = element[0];
				const enter = self.machine.enterPoints[id];
				const exit = self.machine.exitPoints[id];
				let obj = { id: id, enter: enter, exit: exit };
				let enterIcon = window.configJson.IconPaths.whiteCircle;
				let exitIcon = window.configJson.IconPaths.blueCircle;
				if (enter != undefined) {
					enter.setY(enter.y + 0.5);
					window.app.viewer.addImageObject(enterIcon, null, enter, new Vector3(0.3, 0.3, 1), new Vector3(0, 0, 0), function (imageObject) {
						let sph = imageObject;
						obj.enter = sph;
						sph.visible = false;
					}, true);
				}
				if (exit != undefined) {
					exit.setY(exit.y + 0.5);
					window.app.viewer.addImageObject(exitIcon, null, exit, new Vector3(0.3, 0.3, 1), new Vector3(0, 0, 0), function (imageObject) {
						let sph = imageObject;
						obj.exit = sph;
						sph.visible = false;
					}, true);
				}
				self.platformList.push(obj);
			}
			self.platformList[0].exit.visible = true;
			var lianjiePos = new Vector3(-9.0,-6.4,-11.1);
			let lianjieIcon = window.configJson.IconPaths.whiteCircle;
			window.app.viewer.addImageObject(lianjieIcon, function () {
				let path = window.configJson.GameYa.douluo;
				window.open(path,'_self',false);
			}, lianjiePos, new Vector3(0.3, 0.3, 1), new Vector3(0, 0, 0), function (imageObject) {
				self.douluo_lianjie=imageObject;
				imageObject.visible = false;
			}, true);
			
			var videoPos = new Vector3(-8.3,-6.4,-11.1);
			let videoIcon = window.configJson.IconPaths.whiteVideo;
			window.app.viewer.addImageObject(videoIcon, function () {
				let videoPath = window.configJson.VideoPaths.douluo;
				lightbox_open(videoPath);
				PushTrackEvent('阅文','查看斗罗大陆视频');
			}, videoPos, new Vector3(0.3, 0.3, 1), new Vector3(0, 0, 0), function (imageObject) {
				self.douluo_shipin=imageObject;
				imageObject.visible = false;
			}, true);

		}

		this.LightBookState = function () {
			let ids = self.getBookWithIDInJson(self.groundID);
			for (let index = 0; index < ids.length; index++) {
				const element = ids[index];
				self.machine.switchBottomBookState(element, true);
				self.machine.switchTopBookState(element, true);
			}
		}

		this.outputVec = function (name, vec) {
			console.log(name + ": " + vec.x + "," + vec.y + "," + vec.z);
		}

		var self = this;
		var mouse = new Vector2();
		var i = 0;
		var states = ['book', 'roof'];
		this.downClbk = function (event) {
			if (event.type == "touchstart") {
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			}
			window.renderer.domElement.addEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.addEventListener("touchmove", self.moveClbk);
		}

		this.upClbk = function (e) {
			// self.machine.switchBookState(states[++i % states.length]);
			window.renderer.domElement.removeEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.removeEventListener("touchmove", self.moveClbk);
			self.RayrayCaster();
		}

		this.moveClbk = function (event) {

			var movementX = null;
			var movementY = null;
			euler.setFromQuaternion(orbCamera.quaternion);
			if (event.type == "touchmove") {
				movementX = (event.touches[event.touches.length - 1].clientX - mouse.x) * 3 || 0;
				movementY = (event.touches[event.touches.length - 1].clientY - mouse.y) * 3 || 0;
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			} else {
				movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
				movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;
			}
			euler.y -= movementX * 0.002;
			euler.x -= movementY * 0.002;
			euler.x = Math.max(- PI_2, Math.min(PI_2, euler.x));
			orbCamera.quaternion.setFromEuler(euler);
		}

		var originY = 1.25;
		this.checkDisValLeagel = function (vec, distance) {
			let normalizedVec = new Vector3().copy(vec).normalize();
			let nextPos = new Vector3().copy(orbCamera.position).addScaledVector(normalizedVec, distance).setY(0);

			let mesh = null;
			let min = 999;

			self.navMesh.forEach(e => {
				//当前地板点和相机的距离
				let sub = Math.abs(e[0].y - orbCamera.position.y);
				//当前距离和相机高度的差
				let sub2 = Math.abs(originY - sub);
				if (e[0].y < orbCamera.position.y && sub < min && sub > 1) {
					//目前最近的mesh。
					mesh = e;
					min = sub;
				}
			});
			let temp = Math.min(mesh[0].x, mesh[1].x);
			let minX = Math.min(temp, mesh[2].x);

			temp = Math.max(mesh[0].x, mesh[1].x);
			let maxX = Math.max(temp, mesh[2].x);

			temp = Math.min(mesh[0].z, mesh[1].z);
			let minZ = Math.min(temp, mesh[2].z);

			temp = Math.max(mesh[0].z, mesh[1].z);
			let maxZ = Math.max(temp, mesh[2].z);

			if (nextPos.x < maxX && nextPos.x > minX && nextPos.z < maxZ && nextPos.z > minZ) {
				return { vec: normalizedVec, distance: distance };

			}

			return { vec: new Vector3(), distance: 0 };

		}

		this.moveForward = function (distance) {
			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			//获取四元数中x项
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			//与up叉乘算出forward;
			vec.crossVectors(orbCamera.up, vec);

			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.moveBack = function (distance) {
			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			//获取四元数中x项
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			//与up叉乘算出forward;
			vec.multiplyScalar(-1);
			vec.crossVectors(orbCamera.up, vec);

			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.moveRight = function (distance) {
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.moveLeft = function (distance) {
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			vec.multiplyScalar(-1);
			let opts = this.checkDisValLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		var delTime = 0.02;
		var iter = 0;
		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}
			// this.outputVec("now pos: ", orbCamera.position);			
			if (self.jump) {
				orbCamera.position.addScaledVector(self.jumpDir, delTime * self.jumpSpeed);
				let len = new Vector3().subVectors(self.targetList[iter], orbCamera.position).length();
				if (len < 0.2) {
					if (iter == 0) {
						iter = 1;
						self.jumpDir = new Vector3().subVectors(self.targetList[1], orbCamera.position).normalize();
					} else {
						self.jump = false;
						iter = 0;
					}
				}
			}
		
			// console.log(orbCamera.position);
			// delTime = deltaTime;
		}
	}
}

export class StateMachine_yuewen {
	constructor(options) {
		this.states = [];
		this.activeState = -1;
		this.isStarted = false;
		this.options = options;
		this.json = null;
		this.navMesh = [];
		/********************************** */
		// navMesh中三角形的数量：this.navMesh.length
		// navMesh中三角形的对象：this.navMesh[0]
		// navMesh中三角形的顶点：this.navMesh[0][0],this.navMesh[0][1],this.navMesh[0][2]
		/********************************** */

		this.topNodes = {};
		this.bottomNodes = {};
		/********************************** */
		// 获取顶部的某个ID下的平台与书皮：this.topNodes[12].book, 该值为空说明当前平台无书皮
		// 获取顶部的某个ID下的平台与书皮：this.topNodes[12].platform
		// 获取底部的某个ID下的平台与书皮：this.bottomNodes[12].book, 该值为空说明当前平台无书皮
		// 获取底部的某个ID下的平台与书皮：this.bottomNodes[12].platform
		/********************************** */

		this.enterPoints = {};
		this.exitPoints = {};
		/********************************** */
		// 获取某一个ID下的进入点：this.enterPoints[12];
		// 获取某一个ID下的离开点：this.exitPoints[12];
		/********************************** */

		this.switchTopBookState = function (id, showBook) {
			if (this.topNodes[id] && this.topNodes[id].book) {
				this.topNodes[id].book.visible = showBook;
				this.topNodes[id].platform.visible = !showBook;
			}
		}

		this.switchBottomBookState = function (id, showBook) {
			if (this.bottomNodes[id] && this.bottomNodes[id].book) {
				this.bottomNodes[id].book.visible = showBook;
				this.bottomNodes[id].platform.visible = !showBook;
			}
		}

		this.switchAllTopBookState = function (showBook) {
			for (var id in this.topNodes) {
				this.switchTopBookState(id, showBook);
			}
		}

		this.switchAllBottomBookState = function (showBook) {
			for (var id in this.bottomNodes) {
				this.switchBottomBookState(id, showBook);
			}
		}

		this.LightBookState = function (id) {
			if (id >= this.bookNodes.length) {
				return;
			}
			this.bookNodes[id].visible = true;
			this.roofNodes[id].visible = false;

		}

		this.start = function () {
			this.isStarted = true;

			this.states[this.activeState].start();

			/*
			//添加图片对象的示例调用
			this.options.viewer.addImageObject('assets/environment/reflection_dbt.jpg', function()
			{
				console.log('image object is clicked');
			}, new Vector3(0.0, 0.0, 0.0), new Vector3(10, 10, 1), new Vector3(0,0,0), function(imageObject)
			{
				console.log('===============get the object');
			});
			//*/
		}


		var self = this;
		this.initializeStates = function () {
			this.activeState = 0;

			this.states.push(new StartState({ name: 'StartState', params: this.options, machine: this }));
			this.states.push(new FreeMoveState({ name: 'FreeMove', params: this.options, navMesh: this.navMesh, machine: this }));
		}

		this.update = function (deltaTime) {
			if (this.isStarted == false) {
				return;
			}

			if (this.activeState >= 0 && this.activeState < this.states.length) {
				this.states[this.activeState].update(deltaTime);

				if (this.states[this.activeState].isOver) {
					// Jump to next
					this.activeState++;
					if (this.activeState < this.states.length) {
						this.states[this.activeState].start();
					}
				}
			}
		}
		this.initializeStates();
	}
}
