import {
  AmbientLight,
  AnimationMixer,
  AxesHelper,
  Box3,
  Cache,
  Sprite,
  CubeTextureLoader,
  DirectionalLight,
  GridHelper,
  HemisphereLight,
  LinearEncoding,
  LoaderUtils,
  LoadingManager,
  DefaultLoadingManager,
  PMREMGenerator,
  PerspectiveCamera,
  RGBFormat,
  Scene,
  SkeletonHelper,
  UnsignedByteType,
  Vector3,
  Vector2, 
  Quaternion, 
  WebGLRenderer,
  sRGBEncoding,
  FileLoader,
  TextureLoader,
  Object3D,
  Mesh,
  Color,
  VideoTexture,
  SphereBufferGeometry,
  MeshLambertMaterial,
  Shape,
  Group,
  BackSide,
  InstancedMesh,
  Matrix4,
  GeometryUtils,
  DoubleSide,
  ShaderMaterial,
  MeshBasicMaterial,
  Layers,
  Clock,
  BufferGeometry,
  BufferAttribute,
  InterleavedBufferAttribute,
  InterleavedBuffer,
  DynamicDrawUsage,
  MeshMatcapMaterial,
  MeshStandardMaterial,
  ExtrudeBufferGeometry,
  ParametricBufferGeometry,
  PointLight,
  SpotLight,
  FrontSide,
  RepeatWrapping,
  Euler,
  PlaneBufferGeometry,
  Raycaster,
  SpriteMaterial,
} from 'three';
import Stats from 'three/examples/jsm/libs/stats.module.js';
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { GLTFLoader } from '../lib/GLTFLoaderEx.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import {ZipLoader } from './ziploader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass.js';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import {StateMachine} from './stateMachine.js';
import {StateMachine_vangogh} from './stateMachine_vangogh.js';
import {StateMachine_yuewen} from './stateMachine_yuewen.js';
import {StateMachine_lianying} from './stateMachine_lianying.js';
import {StateMachine_dabaitu} from './stateMachine_dabaitu.js';

import { GUI } from 'dat.gui';
import GifLoader from 'three-gif-loader';
import { environments } from '../assets/environment/index.js';
import { createBackground } from '../lib/three-vignette.js';
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
const DEFAULT_CAMERA = '[default]';

const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// glTF texture types. `envMap` is deliberately omitted, as it's used internally
// by the loader but not part of the glTF format.
const MAP_NAMES = [
  'map',
  'aoMap',
  'emissiveMap',
  'glossinessMap',
  'metalnessMap',
  'normalMap',
  'roughnessMap',
  'specularMap',
];

const Preset = {ASSET_GENERATOR: 'assetgenerator'};

Cache.enabled = true;

export class Viewer {

  constructor (el, options) {
    this.el = el;
    this.options = options;

    this.lights = [];
    this.content = null;
    this.mixer = null;
    this.clips = [];
    this.gui = null;

    this.targetMeshNode = null;
    this.targetMeshGroup = null;
    this.targetMeshGroupIndex = 0;
    this.isPlayVideoFinish=false;

    this.state = {
      environment: options.preset === Preset.ASSET_GENERATOR
        ? environments.find((e) => e.id === 'footprint-court').name
        : environments[1].name,
      background: false,
      playbackSpeed: 1.0,
      actionStates: {},
      camera: DEFAULT_CAMERA,
      wireframe: false,
      skeleton: false,
      grid: false,

      // Lights
      addLights: true,
      exposure: 1.0,
      textureEncoding: 'sRGB',
      ambientIntensity: 0.3,
      ambientColor: 0xFFFFFF,
      directIntensity: 0.8 * Math.PI, // TODO(#116)
      directColor: 0xFFFFFF,
      bgColor1: '#ffffff',
      bgColor2: '#353535'
    };

    this.prevTime = 0;

    this.stats = new Stats();
    this.stats.dom.height = '48px';
    [].forEach.call(this.stats.dom.children, (child) => (child.style.display = ''));

    this.scene = new Scene();

    const fov = options.preset === Preset.ASSET_GENERATOR
      ? 0.8 * 180 / Math.PI
      : 60;
    this.defaultCamera = new PerspectiveCamera( fov, el.clientWidth / el.clientHeight, 0.01, 1000 );
    this.activeCamera = this.defaultCamera;
    this.scene.add( this.defaultCamera );

    this.renderer = window.renderer = new WebGLRenderer({antialias: true});
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.setClearColor( 0x000000 );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( el.clientWidth, el.clientHeight );

    this.renderer.sortObjects = false;

    //this.renderer.gammaInput = true;
    //this.renderer.gammaOutput = true;

    this.pmremGenerator = new PMREMGenerator( this.renderer );
    this.pmremGenerator.compileEquirectangularShader();

    this.controls = new FirstPersonControls(this.defaultCamera, this.renderer.domElement);
    this.controls.movementSpeed = 0;
    this.controls.lookSpeed = 0;
    this.controls.lookVertical = false;
    // this.controls.enabled = false;

    this.ENTIRE_SCENE = 0;
    this.BLOOM_SCENE = 1;

    this.bloomLayer = new Layers();
    this.bloomLayer.set(this.BLOOM_SCENE );
      
    // //this.controls.enableZoom = false;

    // //this.controls.enablePan = false;

    // //this.controls.enableRotate = false;

    // this.controls.minPolarAngle = Math.PI * 0.28;
    // this.controls.maxPolarAngle = Math.PI * 0.63;

    // //this.controls.minAzimuthAngle = -Math.PI * 0.5;
    // //this.controls.maxAzimuthAngle = Math.PI * 1.5;
    // //this.controls.maxAzimuthAngle: number;

    this.vignette = createBackground({
      aspect: this.defaultCamera.aspect,
      grainScale: IS_IOS ? 0 : 0.001, // mattdesl/three-vignette-background#1
      colors: [this.state.bgColor1, this.state.bgColor2]
    });
    this.vignette.name = 'Vignette';
    this.vignette.renderOrder = -1;

    this.el.appendChild(this.renderer.domElement);

    this.cameraCtrl = null;
    this.cameraFolder = null;
    this.animFolder = null;
    this.animCtrls = [];
    this.morphFolder = null;
    this.morphCtrls = [];
    this.skeletonHelpers = [];
    this.gridHelper = null;
    this.axesHelper = null;
    this.utils = {};

    this.stateMachine_Main = new StateMachine({viewer: this, camera: this.activeCamera, controls: this.controls, utils: this.utils});
    this.stateMachine_vangogh = new StateMachine_vangogh({viewer: this, camera: this.activeCamera, controls: this.controls, utils: this.utils});
    this.stateMachine_yuewen = new StateMachine_yuewen({viewer: this, camera: this.activeCamera, controls: this.controls, utils: this.utils});
    this.stateMachine_lianying = new StateMachine_lianying({viewer: this, camera: this.activeCamera, controls: this.controls, utils: this.utils});
    this.stateMachine_dabaitu = new StateMachine_dabaitu({viewer: this, camera: this.activeCamera, controls: this.controls, utils: this.utils});
    this.stateMachine = null;

    this.options.showgui = false;
    this.usePostprocessing = true;

    this.params = {
      exposure: 1,
      bloomThreshold: 0.31,
      bloomStrength: 0.5,
      bloomRadius: 0.35
    };

    this.BokenEffectController = {
      focus: 200.0,
      aperture:	2.9,
      maxblur:	1.1
    };

    this.bloomPass = null;

    this.darkMaterial = new MeshBasicMaterial( { color: "black" } );
    this.materials = {};

    this.dynmaicObjectsCallback = [];
    this.dynmaicObjectsMesh = [];
    console.log("*----------Viewer Init!!!!!-----------*");

    this.raycaster = new Raycaster();

    this.cachedGifs = {};

    var viewerSelf = this;
    this.onMouseOrTouchDown = function(event) 
    {
      if(!window.canClick)
      {
        return;
      }
      if (viewerSelf.dynmaicObjectsMesh.length > 0)
      {
        var mouse = new Vector2();

        // Check mouse
        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

        // Check touch
        if (event.touches && event.touches.length == 1 ) 
        {
          mouse.x = ( event.touches[0].pageX / window.innerWidth ) * 2 - 1;
          mouse.y = - ( event.touches[0].pageY / window.innerHeight ) * 2 + 1;
        }

        viewerSelf.raycaster.setFromCamera(mouse, viewerSelf.activeCamera);

        var intersects = viewerSelf.raycaster.intersectObjects(viewerSelf.dynmaicObjectsMesh);
        if (intersects.length > 0)
        {
          var cid = intersects[0].object.clickIndex;
          
          if (viewerSelf.dynmaicObjectsCallback[cid])
          {
            viewerSelf.dynmaicObjectsCallback[cid](intersects[0].object.markId);
          }
        }

        /*
        for (var i = 0 ; i < viewerSelf.dynmaicObjects.length; ++i)
        {
          intersects = viewerSelf.raycaster.intersectObject(viewerSelf.dynmaicObjects[i].mesh);
          if (intersects.length > 0)
          {
            if (viewerSelf.dynmaicObjects[i].clickCallback)
            {
              viewerSelf.dynmaicObjects[i].clickCallback(viewerSelf.dynmaicObjects[i].mesh.markId);
            }
          }
        }
        */
      }
    }

    window.renderer.domElement.addEventListener('click', this.onMouseOrTouchDown, false);
    // window.addEventListener('touchstart', this.onMouseOrTouchDown, false);

    if (this.usePostprocessing)
    {
      this.renderPass = new RenderPass(this.scene, this.activeCamera );

      this.bloomPass = new UnrealBloomPass( new Vector2( el.clientWidth, el.clientHeight ), 1.3, 0.1, 0.25 );
      this.bloomPass.threshold = this.params.bloomThreshold;
      this.bloomPass.strength = this.params.bloomStrength;
      this.bloomPass.radius = this.params.bloomRadius;

      this.bloomComposer = new EffectComposer(this.renderer);
			this.bloomComposer.renderToScreen = false;
			this.bloomComposer.addPass(this.renderPass );
      this.bloomComposer.addPass(this.bloomPass );

      /*
      console.log(FXAAShader);
      this.fxaaPass = new ShaderPass(FXAAShader);
			var pixelRatio = this.renderer.getPixelRatio();
			this.fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( el.clientWidth * pixelRatio );
      this.fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / (  el.clientHeight * pixelRatio );
      //this.fxaaPass.material.uniforms[ 'tDiffuse' ].value = this.bloomComposer.renderTarget2.texture;
      this.fxaaPass.needsSwap = true;
      */

      ///*
      this.finalPass = new ShaderPass(
				new ShaderMaterial( {
					uniforms: {
						baseTexture: { value: null },
						bloomTexture: { value: this.bloomComposer.renderTarget2.texture }
					},
					vertexShader: document.getElementById( 'vertexshader' ).textContent,
					fragmentShader: document.getElementById( 'fragmentshader' ).textContent,
					defines: {}
				} ), "baseTexture"
			);
			this.finalPass.needsSwap = true;
      //*/

      this.fxaaPass = new ShaderPass(FXAAShader);
			var pixelRatio = this.renderer.getPixelRatio();
			this.fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( el.clientWidth * pixelRatio );
      this.fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / (  el.clientHeight * pixelRatio );
      this.fxaaPass.needsSwap = true;

			this.finalComposer = new EffectComposer( this.renderer );
			this.finalComposer.addPass( this.renderPass );
      this.finalComposer.addPass( this.finalPass );
      this.finalComposer.addPass( this.fxaaPass );

      // this.composer = new EffectComposer( this.renderer );
      // this.composer.addPass( this.renderPass );

      // this.bokehPass = new BokehPass(this.scene, this.activeCamera, 
      // {
      //   focus: this.BokenEffectController.focus * 0.1,
      //   aperture:	this.BokenEffectController.aperture * 0.00001,//25,
      //   maxblur: this.BokenEffectController.maxblur,//1.0,

      //   width: el.clientWidth,
      //   height: el.clientHeight
      // } );

      // this.composer.addPass(this.bokehPass);

      // this.composer.addPass(this.bloomPass);
    }

    this.initializeStateMachine();
    
	  if (this.options.showgui)
    {
      this.addAxesHelper();
      this.addGUI();
      if (options.kiosk) this.gui.close();
    }

    this.animate = this.animate.bind(this);
    requestAnimationFrame( this.animate );
    window.addEventListener('resize', this.resize.bind(this), false);
  }

  initializeStateMachine()
  {
    if (paramJson != null)
    {
      if (paramJson.scene == 'vangogh')
      {
        this.stateMachine = this.stateMachine_vangogh;
      }
      else if (paramJson.scene == 'yuewen')
      {
        this.stateMachine = this.stateMachine_yuewen;
      }
      else if (paramJson.scene == 'lianying')
      {
        this.stateMachine = this.stateMachine_lianying;
      }
      else if (paramJson.scene == 'dabaitu')
      {
        this.stateMachine = this.stateMachine_dabaitu;
      }
      else
      {
        this.stateMachine = this.stateMachine_Main;
      }
    } 
  }

  setEmissiveStrength(strength /* 0.0 - 1.0*/)
  {
    this.bloomPass.strength = strength;
  }

  addClickableSphere(clickCallback, position, radius, objectCallback, markId, color)
  {
    var _viewer = this;

    var geometry = new SphereBufferGeometry(radius, 7, 7)
    var material = new MeshStandardMaterial({color: color ? new Color(color) : new Color(0xffffff)});
    var dynObject = new Mesh(geometry, material);

    if (position)
    {
      dynObject.position.x = position.x;
      dynObject.position.y = position.y;
      dynObject.position.z = position.z;
    }

    if (markId)
    {
      dynObject.markId = markId;
    }

    _viewer.scene.add(dynObject);

    dynObject.clickIndex = _viewer.dynmaicObjectsMesh.length;
    _viewer.dynmaicObjectsMesh.push(dynObject);
    _viewer.dynmaicObjectsCallback.push(clickCallback);

    if (objectCallback)
    {
      objectCallback(dynObject);
    }
  }

  

  /**
   * 
   * @param {string} imageUrl 
   * @param {function} clickCallback 
   * @param {Vector3} position 
   * @param {Vector3} scale 
   * @param {Vector3} rotation 
   * @param {function} objectCallback 
   * @param {bool} isBillboard 
   */
  addImageObject(imageUrl, clickCallback, position, scale, rotation, objectCallback, isBillboard, markId = "", disableEmissive = true)
  {
    var _viewer = this;

    if (imageUrl)
    {
      if (imageUrl.endsWith('.gif'))
      {
        var texture = null;

        if (this.cachedGifs[imageUrl])
        {
          texture = this.cachedGifs[imageUrl];
        }
        else
        {
          var loader = new GifLoader();
          texture = loader.load(
            imageUrl,
            function (reader) {
              //console.log(reader.numFrames());
            },
  
            function (xhr) {
              //console.log(`${(xhr.loaded / xhr.total * 100)}% loaded`);
            },
  
            function () {
              console.error('An error happened.');
            }
          );

          this.cachedGifs[imageUrl] = texture;
        }

        var dynObject = null;
        if (isBillboard)
        {
          var spriteMaterial = new SpriteMaterial( { map: texture } );
          dynObject= new Sprite( spriteMaterial );
        }
        else
        {
          var geometry = new PlaneBufferGeometry(1, 1);
          var material = new MeshStandardMaterial({transparent: true, envMapIntensity: 0.0, emissive: new Color(0xffffff), emissiveMap: texture, color: new Color(0x000000), map: texture});
          dynObject = new Mesh(geometry, material);
        }

        dynObject.material.alphaTest = 0.1;

        if (position)
        {
          dynObject.position.x = position.x;
          dynObject.position.y = position.y;
          dynObject.position.z = position.z;
        }

        if (scale)
        {
          dynObject.scale.x = scale.x;
          dynObject.scale.y = scale.y;
          dynObject.scale.z = scale.z;
        }

        if (rotation)
        {
          dynObject.rotation.x = rotation.x;
          dynObject.rotation.y = rotation.y;
          dynObject.rotation.z = rotation.z;
        }

        if (markId)
        {
          dynObject.markId = markId;
        }

        if (disableEmissive)
        {

        }
        else
        {
          var BLOOM_SCENE = 1;
          dynObject.layers.enable(BLOOM_SCENE);
        }

        _viewer.scene.add(dynObject);

        //_viewer.dynmaicObjects.push({mesh: dynObject, clickCallback: clickCallback});
        dynObject.clickIndex = _viewer.dynmaicObjectsMesh.length;
        _viewer.dynmaicObjectsMesh.push(dynObject);
        _viewer.dynmaicObjectsCallback.push(clickCallback);

        if (objectCallback)
        {
          objectCallback(dynObject);
        }
      }
      else
      {
        /*********************************** */
        new TextureLoader().load(imageUrl, (texture) => {
          var dynObject = null;
          if (isBillboard)
          {
            var spriteMaterial = new SpriteMaterial( { map: texture } );
            dynObject= new Sprite( spriteMaterial );
            dynObject.metalness=0;
            dynObject.emissive=0;
          }
          else
          {
            var geometry = new PlaneBufferGeometry(1, 1);
            var material = new MeshStandardMaterial({transparent: true, envMapIntensity: 0.0, emissive: new Color(0xffffff), emissiveMap: texture, color: new Color(0x000000), map: texture});
            dynObject = new Mesh(geometry, material);
          }

          dynObject.material.alphaTest = 0.1;

          if (position)
          {
            dynObject.position.x = position.x;
            dynObject.position.y = position.y;
            dynObject.position.z = position.z;
          }

          if (scale)
          {
            dynObject.scale.x = scale.x;
            dynObject.scale.y = scale.y;
            dynObject.scale.z = scale.z;
          }

          if (rotation)
          {
            dynObject.rotation.x = rotation.x;
            dynObject.rotation.y = rotation.y;
            dynObject.rotation.z = rotation.z;
          }

          if (markId)
          {
            dynObject.markId = markId;
          }

          if (disableEmissive)
          {

          }
          else
          {
            var BLOOM_SCENE = 1;
            dynObject.layers.enable(BLOOM_SCENE);
          }

          _viewer.scene.add(dynObject);

          //_viewer.dynmaicObjects.push({mesh: dynObject, clickCallback: clickCallback});
          dynObject.clickIndex = _viewer.dynmaicObjectsMesh.length;
          _viewer.dynmaicObjectsMesh.push(dynObject);
          _viewer.dynmaicObjectsCallback.push(clickCallback);

          if (objectCallback)
          {
            objectCallback(dynObject);
          }

        }, undefined, undefined ); 
      }
    }
  }

  animate (time) {

    requestAnimationFrame( this.animate );

    const dt = (time - this.prevTime) / 1000;

    this.stats.update();
    this.mixer && this.mixer.update(dt);
    this.render();

    this.prevTime = time;

    if (this.stateMachine != null)
    {
      this.stateMachine.update(dt);
    }
    if(window.isShowPostcard)
    {
      var img = $('.viewer-canvas img');
      var imgMarginLeft = img.css('marginLeft');
      var imgMarginTop = img.css('marginTop');
      var imgWidth = parseFloat(img.css('width').replace("px", ""));
      var imgHeight = parseFloat(img.css('height').replace("px", ""));      
      //等比例锁定图片位置
      let leftT = parseFloat(imgMarginLeft.replace("px", "")) + imgWidth * 0.23;
      let topT = parseFloat(imgMarginTop.replace("px", "")) + imgHeight * 0.6;
      window.imageItem.css({
        left: leftT + "px",
        top: topT + "px",
        width: imgWidth * 0.15,
        height: imgHeight * 0.05,
        zIndex: 99999
      });     
    }
  }

  render () {

    //this.renderer.render( this.scene, this.activeCamera );

    if (this.usePostprocessing == false)
    {
      this.renderer.render( this.scene, this.activeCamera );
    }
    else
    {
      //this.renderer.clear();
      //this.composer.render();

      this.renderBloom( true );
      this.finalComposer.render();
    }

    // console.log('=============camera');
    // console.log(this.defaultCamera.position);
    // console.log(this.controls.target);
        
    if (this.state.grid) {
      this.axesCamera.position.copy(this.defaultCamera.position)
      this.axesCamera.lookAt(this.axesScene.position)
      this.axesRenderer.render( this.axesScene, this.axesCamera );
    }
  }

  darkenNonBloomed( obj ) 
  {
    if ( obj.isMesh && _viewer.bloomLayer.test( obj.layers ) === false ) 
    {
      _viewer.materials[ obj.uuid ] = obj.material;
      obj.material = _viewer.darkMaterial;

    }

  }

  restoreMaterial( obj ) 
  {
    var _viewer = this;
    if ( _viewer.materials[ obj.uuid ] ) 
    {
      obj.material = _viewer.materials[ obj.uuid ];
      delete _viewer.materials[ obj.uuid ];
    }
  }

  renderBloom( mask )
  {
    if ( mask === true ) 
    {
      //this.scene.traverse(this.darkenNonBloomed );

      var _viewer = this;
      this.scene.traverse(function (obj)
      {
        if ((obj.isMesh && _viewer.bloomLayer.test( obj.layers ) === false) ||
            (obj instanceof Sprite && _viewer.bloomLayer.test( obj.layers ) === false)) 
        {
          _viewer.materials[ obj.uuid ] = obj.material;
          obj.material = _viewer.darkMaterial;

        }
      });

      this.bloomComposer.render();

      //this.scene.traverse(this.restoreMaterial );
      this.scene.traverse(function (obj)
      {
        if ( _viewer.materials[ obj.uuid ] ) 
        {
          obj.material = _viewer.materials[ obj.uuid ];
          delete _viewer.materials[ obj.uuid ];
        }
      });
    } 
    else
    {
      this.activeCamera.layers.set( this.BLOOM_SCENE );
      this.bloomComposer.render();
      this.activeCamera.layers.set( this.ENTIRE_SCENE );
    }

  }

  resize () {

    const {clientHeight, clientWidth} = this.el.parentElement;

    this.defaultCamera.aspect = clientWidth / clientHeight;
    this.defaultCamera.updateProjectionMatrix();
    this.vignette.style({aspect: this.defaultCamera.aspect});
    this.renderer.setSize(clientWidth, clientHeight);

    if (this.bloomComposer)
    {
      //this.composer.setSize(clientWidth, clientHeight);
      this.bloomComposer.setSize(clientWidth, clientHeight);
      this.finalComposer.setSize(clientWidth, clientHeight);

      var pixelRatio = this.renderer.getPixelRatio();
      this.fxaaPass.material.uniforms['resolution'].value.x = 1 / (clientWidth * pixelRatio);
      this.fxaaPass.material.uniforms['resolution'].value.y = 1 / (clientHeight * pixelRatio);
    } 

	/*
    this.axesCamera.aspect = this.axesDiv.clientWidth / this.axesDiv.clientHeight;
    this.axesCamera.updateProjectionMatrix();
    this.axesRenderer.setSize(this.axesDiv.clientWidth, this.axesDiv.clientHeight);
	*/
  }

  setMeshNode(mesh, group)
  {
      this.targetMeshNode = mesh;
      this.targetMeshGroup = group;
  }

  switchSubNode(inc)
  {
      console.log(this.targetMeshNode);

      this.targetMeshGroupIndex += inc;
      this.targetMeshGroupIndex = Math.max(0, this.targetMeshGroupIndex);
      this.targetMeshGroupIndex = Math.min(this.targetMeshGroupIndex, this.targetMeshGroup.length);

      var group = this.targetMeshGroup[this.targetMeshGroupIndex];

      var start = (group.start) * 3;
      var count = (group.count) * 3;
      this.targetMeshNode.setDrawRange(start, count);

      console.log('start: ' + start + ', count: ' + count);
      var idString = '';
      var posString = '';
      for (var i = start; i < start + count ; ++i)
      {
          idString += (this.targetMeshNode.index.array[i] + ',');
          //posString += (this.targetMeshNode.attributes.position.array[i * ])
      }

      console.log(idString);
      
      console.log('-------------switch sub group: ' +  this.targetMeshGroupIndex);
  }

  load ( url, rootPath, assetMap ) {

    var _viewer = this;
    const baseURL = LoaderUtils.extractUrlBase(url);

    // Load.
    return new Promise((resolve, reject) => {

      const manager = new LoadingManager();

      // Intercept and override relative URLs.
      manager.setURLModifier((url, path) => {

        // URIs in a glTF file may be escaped, or not. Assume that assetMap is
        // from an un-escaped source, and decode all URIs before lookups.
        const normalizedURL = rootPath + decodeURI(url)
          .replace(baseURL, '')
          .replace(/^(\.?\/)/, '');

        if (assetMap.has(normalizedURL)) {
          const blob = assetMap.get(normalizedURL);
          const blobURL = URL.createObjectURL(blob);
          blobURLs.push(blobURL);
          return blobURL;
        }

        return (path || '') + url;

      });

	  manager.onProgress = function ( url, itemsLoaded, itemsTotal )
      {
          //console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
	  };

	  function loadJsonList(urls, mananger, callback)
	  {
	      var dataList = [];
	      var counter = 0;
	      var urlList = urls;

	      function loadUrl(url, manager, callback)
	      {
	          var loader = new FileLoader(manager);
	          loader.load(url , function(text) 
	          {
	              var data = text;

	              counter++;

	              //console.log(data);
	              if (callback)
	              {
	                  callback(data);
	              }
	          });
	      }

	      function parseDataCallback(data)
	      {
	          dataList.push(JSON.parse(data));

	          if (counter < urlList.length)
	          {
	              loadUrl(urlList[counter], manager, parseDataCallback);
	          }
	          else
	          {
	              if (callback)
	              {
	                  callback(dataList);
	              }
	          }
	      }

	      loadUrl(urlList[counter], manager, parseDataCallback);
	  }
    
    var videoTexture = null;

    function setVideoTextures()
    {
      if (videoTexture == null)
      {
        var video = document.getElementById( 'video' );
        video.play();
  
        videoTexture = new VideoTexture( video );
      }
    }

    function uniformScene(sceneModel, _uniforSize)
    {
        // Uniform model
        var uniformSize = _uniforSize ? _uniforSize : 20;

        var objBox3 = new Box3().setFromObject(sceneModel);

        var centerOffset = new Vector3();
        centerOffset.x = -(objBox3.min.x + objBox3.max.x) * 0.5;
        centerOffset.y = -(objBox3.min.y + objBox3.max.y) * 0.5;
        centerOffset.z = -(objBox3.min.z + objBox3.max.z) * 0.5;

        var maxSize = Math.max((objBox3.max.x - objBox3.min.x) , Math.max((objBox3.max.y - objBox3.min.y) , (objBox3.max.z - objBox3.min.z)));
        var scale = uniformSize / maxSize;

        sceneModel.scale.x = scale;
        sceneModel.scale.y = scale;
        sceneModel.scale.z = scale;

        sceneModel.translateX(centerOffset.x * scale);
        sceneModel.translateY(centerOffset.y * scale);
        sceneModel.translateZ(centerOffset.z * scale);
    }

    function setupNavMesh(sceneModel)
    {
      sceneModel.updateMatrixWorld(true);
      
      var collectNavMesh = function(rootNode)
      {
        rootNode.traverse(function (node)
        {
          if (node instanceof Mesh)
          {
            for (var i = 0; i < node.geometry.index.count; i += 3)
            {
              var ti0 = node.geometry.index.getX(i + 0);
              var ti1 = node.geometry.index.getX(i + 1);
              var ti2 = node.geometry.index.getX(i + 2);
    
              var v1 = new Vector3();
              var v2 = new Vector3();
              var v3 = new Vector3();

              v1.fromBufferAttribute(node.geometry.attributes.position, ti0).applyMatrix4(node.matrixWorld);
              v2.fromBufferAttribute(node.geometry.attributes.position, ti1).applyMatrix4(node.matrixWorld);
              v3.fromBufferAttribute(node.geometry.attributes.position, ti2).applyMatrix4(node.matrixWorld);
    
              _viewer.stateMachine.navMesh.push([v1, v2, v3]);
    
              /*
              var geometry = new SphereBufferGeometry(0.5, 20, 20);
              var material = new MeshBasicMaterial({emissive:0x0000ff});
              var mesh = new Mesh( geometry, material );
              mesh.position.x = v3.x;
              mesh.position.y = v3.y;
              mesh.position.z = v3.z;
              _viewer.scene.add(mesh);
              */
            }
            //*/

            // Hide mesh
            node.visible = false;
          }
        });
      }

      var collectEEPoint = function(rootNode)
      {
        rootNode.traverse(function (node)
        {
          if (node instanceof Mesh)
          {
            var position = node.position.applyMatrix4(node.matrixWorld);

            if (node.parent.name.endsWith('_enter'))
            {
              var id = parseInt(node.parent.name.replace('_enter', ''));
              _viewer.stateMachine.enterPoints[id] = position;
            }
            else if (node.parent.name.endsWith('_exit'))
            {
              var id = parseInt(node.parent.name.replace('_exit', ''));
              _viewer.stateMachine.exitPoints[id] = position;

              // To Debug
              /*
              var geometry = new SphereBufferGeometry(0.5, 20, 20);
              var material = new MeshBasicMaterial({emissive:0x0000ff});
              var mesh = new Mesh( geometry, material );
              mesh.position.x = position.x;
              mesh.position.y = position.y;
              mesh.position.z = position.z;
              _viewer.scene.add(mesh);
              */
            }

            // Hide mesh
            node.visible = false;
          }
        });
      }

      var collectDummyPoint = function(rootNode)
      {
        rootNode.traverse(function (node)
        {
          if (node instanceof Mesh)
          {
            //console.log(node.parent.name);
            var position = new Vector3();
            var quaternion = new Quaternion();
            var scale = new Vector3();

            node.matrixWorld.decompose( position, quaternion, scale );

            var rotation = new Euler().setFromQuaternion( quaternion);

            _viewer.stateMachine_dabaitu.dummyObjects[node.parent.name] = {position: position, rotation: rotation};
            console.log("dummy name: "+node.parent.name);
            // To Debug
            /*
            var geometry = new SphereBufferGeometry(0.5, 20, 20);
            var material = new MeshBasicMaterial({emissive:0x0000ff});
            var mesh = new Mesh( geometry, material );
            mesh.position.x = position.x;
            mesh.position.y = position.y;
            mesh.position.z = position.z;
            mesh.scale.x *= 2.0;
            mesh.rotation.x = rotation.x;
            mesh.rotation.y = rotation.y;
            mesh.rotation.z = rotation.z;
            //mesh.scale.y *= 10.0;
            //mesh.scale.z *= 10.0;
            _viewer.scene.add(mesh);
            //*/

            // Hide mesh
            node.visible = false;
          }
        });
      }

      var navMeshRoots = [];
      var eePointRoots = [];
      var dummyRoots = [];
      sceneModel.traverse(function (rootNode)
      {
        if (rootNode.name == "NavMesh")
        {
          navMeshRoots.push(rootNode);
        }

        if (rootNode.name == "EnterExit")
        {
          eePointRoots.push(rootNode);
        }

        if (rootNode.name == 'p1' || rootNode.name == 'p2' || rootNode.name == 'p3' || rootNode.name == 'p4' || rootNode.name == 'p5' || rootNode.name == 'p6' || rootNode.name == 'p7' || rootNode.name == 'p8')
        {
          dummyRoots.push(rootNode);
        }
      });

      for (var i = 0; i < navMeshRoots.length ; ++i)
      {
        collectNavMesh(navMeshRoots[i]);
      }

      for (var i = 0; i < eePointRoots.length ; ++i)
      {
        collectEEPoint(eePointRoots[i]);
      }

      for (var i = 0; i < dummyRoots.length ; ++i)
      {
        collectDummyPoint(dummyRoots[i]);
      }
    }

    function setupModels(sceneModel)
    {
      if (paramJson != null)
      {
        if (paramJson.scene == 'vangogh')
        {
          uniformScene(sceneModel, 5);

          sceneModel.traverse(function (node)
          {
            if (node instanceof Mesh)
            {
              node.material.envMapIntensity = 0.0;
              node.material.map = null;
              node.material.color = new Color(0xffffff);
              node.material.emissiveIntensity = 1.0;
            }
          });
        }
        else if (paramJson.scene == 'dabaitu')
        {
          uniformScene(sceneModel, 40);

          sceneModel.traverse(function (node)
          {
            if (node instanceof Mesh)
            {
              node.material = node.material.clone();
              var splashIndex = node.name.lastIndexOf('_');
              var meshName = node.name;
              if (splashIndex >= 0 && splashIndex == node.name.length - 2)
              {
                meshName = node.name.substring(0, splashIndex);
              }

              if (_viewer.stateMachine_dabaitu.meshMap[meshName] == undefined)
              {
                _viewer.stateMachine_dabaitu.meshMap[meshName] = [];
              }
              _viewer.stateMachine_dabaitu.meshMap[meshName].push(node);
              
              node.material.envMapIntensity = 0.0;

              if (node.material.name == 'wall')
              {
                node.material.map.wrapS = RepeatWrapping;
                node.material.map.wrapT = RepeatWrapping;

                node.material.envMapIntensity = 0.8;
              }

              if (node.material.name == 'Blue' || 
                  node.material.name == 'Red' || 
                  node.material.name == 'White')
              {
                node.material.envMapIntensity = 1.0;
                node.material.metalness = 0.8;
                node.material.roughness = 0.0;
              }

              if (node.material.name == 'White')
              {
                node.material.color = new Color(0xffffff);
                node.material.emissive = new Color(0x222222);
              }


              if (node.material.name == 'purple')
              {
                node.material.emissive = node.material.color;
              }
            }
          });
        }
        else if (paramJson.scene == 'lianying')
        {
          uniformScene(sceneModel, 50);

          // setup part group
          sceneModel.traverse(function (node)
          {
            if (node instanceof Mesh)
            {
              if (node.name.indexOf('Bed01_L1') >=0 ||
                  node.name.indexOf('Mation_L2') >=0) // Left solid
              {
                _viewer.stateMachine_lianying.leftSolid.push(node);
              }
              else if (node.name.indexOf('Bed02Line_L1') >=0 ||
                       node.name.indexOf('Bed02Ray_L1') >=0 ||
                       node.name.indexOf('Mation_line_L01a') >=0 ||
                       node.name.indexOf('Mation_ray_L01a') >=0) // Left ray
              {
                _viewer.stateMachine_lianying.leftRay.push(node);

                var BLOOM_SCENE = 1;
                node.layers.enable(BLOOM_SCENE);
              }
              else if (node.name.indexOf('Bed01_R1') >= 0 ||
                       node.name.indexOf('Mation_R1') >= 0) // Right solid
              {
                _viewer.stateMachine_lianying.rightSolid.push(node);
              }
              else if (node.name.indexOf('Bed02Line_R1') >= 0 ||
                       node.name.indexOf('Bed02Ray_R1') >= 0 || 
                       node.name.indexOf('Mation_line_R01a') >= 0 ||
                       node.name.indexOf('Mation_ray_R01a') >= 0) // Right ray
              {
                _viewer.stateMachine_lianying.rightRay.push(node);

                var BLOOM_SCENE = 1;
                node.layers.enable(BLOOM_SCENE);
              }
            }
          });

          sceneModel.traverse(function (node)
          {
            if (node instanceof Mesh)
            {
              //console.log(node.name);
              //console.log(node.material.name);

              if (node.material.name == "Blue_Glass02_new")
              {
                //node.visible = false;
                node.material.transparent = true;
                node.material.opacity = 0.5;
                node.material.color = new Color(0x222222);

                var newMat = node.material.clone();
                newMat.onBeforeCompile = function(shader)
                {
                  shader.fragmentShader = shader.fragmentShader
                    .replace( '#include <tonemapping_fragment>', 
                    "float dotv = dot(vec3(0, 0, 1), normalize(vNormal.xyz));" + 
                    "dotv = 1.0 - dotv;" +
                    "dotv = pow(dotv, 10.0);" +  
                    "vec3 finalColor = vec3(0.1, 0.1, 1.0) * dotv * 2.0;" + 
                    "gl_FragColor = vec4(finalColor, 0.8);\n" + 
                    "#include <tonemapping_fragment>" );
                };
                node.material = newMat;

                var BLOOM_SCENE = 1;
                node.layers.enable(BLOOM_SCENE);
              }

              if (node.material.name == 'logo_new')
              {
                node.material.map = null;
                node.material.color = new Color(0x000000);
                node.material.envMapIntensity = 0.0;
              }

              if (node.name == 'Office_001a_2')
              {
                node.material.map.wrapS = RepeatWrapping;
                node.material.map.wrapT = RepeatWrapping;
              }
            }
          });

        }
        else if (paramJson.scene == 'yuewen')
        {
          //sceneModel.scale.x = 0.5;
          //sceneModel.scale.y = 0.5;
          //sceneModel.scale.z = 0.5;



          uniformScene(sceneModel, 40);

          // setup part group
          sceneModel.traverse(function (node)
          {
            if (node instanceof Mesh)
            {
              if (node.name.indexOf('0') == 0)
              {
                var id = parseInt(node.name.substring(0, 3));
                

                if (node.name.indexOf('top') >= 0) // Top Node
                {
                  if (!_viewer.stateMachine_yuewen.topNodes[id])
                  {
                    _viewer.stateMachine_yuewen.topNodes[id] = {book: null, platform: null};
                  }

                  if (node.name.indexOf('_pic') >=0) // Book Node
                  {
                    _viewer.stateMachine_yuewen.topNodes[id].book = node;
                  }
                  else // Platform Node
                  {
                    _viewer.stateMachine_yuewen.topNodes[id].platform = node;
                  }
                }
                else // Bottom Node
                {
                  if (!_viewer.stateMachine_yuewen.bottomNodes[id])
                  {
                    _viewer.stateMachine_yuewen.bottomNodes[id] = {book: null, platform: null};
                  }

                  if (node.name.indexOf('_pic') >=0) // Book Node
                  {
                    _viewer.stateMachine_yuewen.bottomNodes[id].book = node;
                  }
                  else // Platform Node
                  {
                    _viewer.stateMachine_yuewen.bottomNodes[id].platform = node;
                  }
                }
              }
            }
          });

          // Hide all top book
          _viewer.stateMachine_yuewen.switchAllTopBookState(false);
//todo
          //Hide all bottom book
          _viewer.stateMachine_yuewen.switchAllBottomBookState(false);

          sceneModel.traverse(function (node)
          {
            if (node instanceof Mesh)
            {
              //console.log(node.name);

              node.material.envMapIntensity = 0.0;

              if (node.material.name == 'ceiling' ||
                  node.material.name == 'floor')
              {
                node.material.envMapIntensity = 1.0;
              }

              if (node.material.map)
              {
                node.material.map.wrapS = RepeatWrapping;
                node.material.map.wrapT = RepeatWrapping;
              }

              if (node.material.name == 'wall')
              {
                //console.log(node.material);
                node.material.map = null;
                node.material.emissive = new Color(0xffffff);
                //console.log(node.material.emissiveMap);
                //node.material.normalMap = null;
              }
            }
          });
        }
        else
        {
          // Adjust size
          sceneModel.scale.x = 80.0;
          sceneModel.scale.y = 80.0;
          sceneModel.scale.z = 80.0;

          // Rotate momodel
          sceneModel.rotation.y = Math.PI - 0.18;

          _viewer.stateMachine_Main.mainScenes.push(sceneModel);

          // Adjust material
          sceneModel.traverse(function (node)
          {
              if (node instanceof Mesh)
              {
                  if (node.name.indexOf('Top_001a') >= 0)
                  {
                    //node.visible = false;

                    var BLOOM_SCENE = 1;
                    node.layers.enable(BLOOM_SCENE);
                  }

                  if (node.material.name == 'logo_wall_01a')
                  {
                    node.material.color = new Color(0xffffff);
                    node.material.emissive = new Color(0xffffff);
                    node.material.emissiveMap = node.material.map;
                    node.material.emissiveIntensity = 2.0;;
                    //node.material.envMapIntensity = 1.0;

                    //node.material.map.wrapS = ClampToEdgeWrapping;
                    //node.material.map.wrapT = ClampToEdgeWrapping;
                    node.material.map.repeat.y = 13;
                    node.material.map.offset.y = 0.3;
                    node.material.map.needsUpdate = true;
                    node.scale.y *= 0.2;
                  }

                  if (node.material.name == 'logo_wall_02a')
                  {
                    node.visible = false;

                    /*
                    var BLOOM_SCENE = 1;
                    node.layers.enable(BLOOM_SCENE);

                    _viewer.stateMachine_Main.beginToShineLogo(node.material);
                    */
                  }

                  // if (node.material.emissiveMap != null)
                  // {
                  //   node.material.envMapIntensity = 0.0;
                  // }

                  //node.material.envMapIntensity = 0.0;

                  if (node.name.indexOf('FloatScreen') >= 0)
                  {
                    setVideoTextures();
                    node.material = new MeshStandardMaterial( {color: 0xffffff, map: videoTexture});
                  }
                  
                  //console.log(node.name + ", " + node.material.name);

                  /********************************************************** */
                  if (node.material.name == 'ShaderFunction1')
                  {
                    node.material.color = new Color(0x888888);
                  }

                  if (node.name == 'Glass')
                  {
                    node.visible = false;
                    node.material.transparent = true;
                    node.material.opacity = 0.9;
                  }

                  if (node.material.name.indexOf('Floor001a') >= 0)
                  {
                    //node.visible = false;
                    node.material.color = new Color(0xaaaaaa);
                    node.material.metalness = 0.6;
                    node.material.roughness = 0.05;
                    node.material.envMapIntensity = 0.5;
                  }
                  
                  if (node.name.indexOf('Glass_way') >= 0)
                  {
                    node.material.transparent = true;
                    node.material.metalness = 1.0;
                    node.material.roughness = 0.0;
                    node.material.opacity = 0.6;
                  }

                  if (node.material.name == 'Text')
                  {
                    //if (node.name =='Text001a')
                    {
                      node.material.renderOrder = 10;

                      node.material.color = new Color(0xffffff);
                      node.material.map.wrapS = RepeatWrapping;
                      node.material.map.wrapT = RepeatWrapping;
                      node.material.map.repeat.y = 5.5;
                      node.material.map.offset.y = -0.1;
                      node.material.map.needsUpdate = true;
                      node.material.emissiveMap = node.material.map;
                      //node.material.map = null;
                      //node.material.transparent = true;

                      var BLOOM_SCENE = 1;
                      node.layers.enable(BLOOM_SCENE);

                      node.position.y = node.position.y - 0.13;
                      node.scale.y *= 2.5;
                      //node.material.emissiveIntensity = 0.1;
                      node.material.opacity = 0.6;
                    }
                  }

                  if (node.material.name == 'efractionWhite_new')
                  {
                    node.material.transparent = true;
                    node.material.metalness = 1.0;
                    node.material.roughness = 0.0;
                    node.material.opacity = 0.6;
                  }

                  if (node.material.name == 'Elight_new')
                  {
                    node.material.color = new Color(0x000000);
                    node.material.envMapIntensity = 0.0;

                    _viewer.stateMachine_Main.beginToLive(node.material);
                  }
                  
                  if (node.material.name.indexOf('ELight 2_new') >= 0 ||
                      node.material.name.indexOf('ELight_TV00a_new') >= 0 ||
                      node.material.name.indexOf('ELight_TV02a_new') >= 0 ||
                      node.material.name.indexOf('ELight_TV03a_new') >= 0)
                      {
                        node.material.color = new Color(0x000000);
                        //node.material.emissive = new Color(0xffffff);
                        node.material.emissiveIntensity = 3.0;
                        node.material.envMapIntensity = 0.0;
                      }

                  if (node.material.name.indexOf('Elight_2_new') >= 0)
                  {
                    node.material.color = new Color(0x666666);
                    node.material.envMapIntensity = 0.0;
                  }

                  if (node.material.name == "elight1")
                  {
                    node.material.color = new Color(0x333333);

                    var BLOOM_SCENE = 1;
                    node.layers.enable(BLOOM_SCENE);
                  }
                  /******************************************************** */
              }
          });
        }
      }
    }

      var self = this;
      function loadScene(configJson)//fileUrl, matrixDesc, structDesc)
      {
          const loader = new GLTFLoader(manager);
          loader.setCrossOrigin('anonymous');
          loader.setWebGLRenderer(self.renderer);

          const dracoLoader = new DRACOLoader();
          dracoLoader.setDecoderPath( 'lib/draco/' );
          //loader.setDRACOLoader( dracoLoader );

          const blobURLs = [];

          loader.load(configJson.fileUrl, (gltf) => {

              const scene = gltf.scene || gltf.scenes[0];
              const clips = gltf.animations || [];

              self.setContent(scene, clips);

              blobURLs.forEach(URL.revokeObjectURL);

              // See: https://github.com/google/draco/issues/349
              // THREE.DRACOLoader.releaseDecoderModule();

              setupModels(gltf.scene);

              resolve(gltf);

              setupNavMesh(gltf.scene);

              notifyCompleteLoading();
          }, 
          function ( xhr ) 
          {
              updateProgressBar(( xhr.loaded / xhr.total * 100 ).toFixed(1), configJson.isFromZip ? 'parsing...':'loading...');
          }, reject);
      }

      if (url.toLowerCase().endsWith('.zip'))
      {
          new Promise( function( resolve, reject ) {

              if ( url.match( /\.zip$/ ) ) {

                  new ZipLoader().load( url , function ( xhr ) 
                  {
                      updateProgressBar(( xhr.loaded / xhr.total * 100 ).toFixed(1), 'loading...');
                  }).then( function( zip ) {

                      manager.setURLModifier( zip.urlResolver );

                      resolve({
                          fileUrl: zip.find( /\.(gltf|glb)$/i )[0], 
                          matrixDesc: zip.find('smatrix.json')[0], 
                          structDesc: zip.find('structdesc.json')[0]});

                  } );

              } else {

                  resolve( url );

              }

          } ).then( function ( congigJson ) 
          {
              loadScene({
                  fileUrl: congigJson.fileUrl,
                  matrixDesc: congigJson.matrixDesc, 
                  structDesc: congigJson.structDesc, 
                  isFromZip: true});
          } );
      }
      else
      {
          loadScene({fileUrl: url});
        }
		
      notifyStartLoading();
    });

  }

  /**
   * @param {THREE.Object3D} object
   * @param {Array<THREE.AnimationClip} clips
   */
  setContent ( object, clips ) {

    this.clear();

    const box = new Box3().setFromObject(object);
    const size = box.getSize(new Vector3()).length();
    const center = box.getCenter(new Vector3());

    // this.controls.reset();

    //object.position.x += (object.position.x - center.x);
    //object.position.y += (object.position.y - center.y);
    //object.position.z += (object.position.z - center.z);
    
    //this.controls.maxDistance = size * 10;
    this.defaultCamera.near = size / 100;
    this.defaultCamera.far = size * 100;
    this.defaultCamera.updateProjectionMatrix();

    if (this.options.cameraPosition) {

      this.defaultCamera.position.fromArray( this.options.cameraPosition );
      this.defaultCamera.lookAt( new Vector3() );
    } 
    else 
    {

      this.defaultCamera.position.copy(center);

      this.defaultCamera.position.x += size / 2.0;
      this.defaultCamera.position.y += size / 5.0;
      this.defaultCamera.position.z += size / 2.0;


      // this.controls.target = new Vector3(0.0, 10, 0.0);

      //this.controls.enableZoom = false;

      //this.controls.enablePan = false;

      //this.controls.enableRotate = false;

      //this.controls.minPolarAngle = Math.PI * 0.28;
      //this.controls.maxPolarAngle = Math.PI * 0.63;

      this.setupCamera();
    }

    this.setCamera(DEFAULT_CAMERA);

	/*
    this.axesCamera.position.copy(this.defaultCamera.position)
    this.axesCamera.lookAt(this.axesScene.position)
    this.axesCamera.near = size / 100;
    this.axesCamera.far = size * 100;
    this.axesCamera.updateProjectionMatrix();
    this.axesCorner.scale.set(size, size, size);
	*/
	
    // this.controls.saveState();

    this.scene.add(object);
    this.content = object;

    this.state.addLights = true;

    this.content.traverse((node) => {
      if (node.isLight) {
        this.state.addLights = false;
      } else if (node.isMesh) {
        // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
          //node.material.depthWrite = !(node.material.opacity < 1.0);
      }
    });

    this.setClips(clips);

    this.updateLights();
	
    if (this.options.showgui)
    {
        this.updateGUI();
    }
    else
    {
        this.updateClips();
    }
    
    this.updateEnvironment();
    this.updateTextureEncoding();
    this.updateDisplay();

    window.content = this.content;
    console.info('[Web3D Viewer]');
    //this.printGraph(this.content);

  }

  printGraph (node) {

    console.group(' <' + node.type + '> ' + node.name);
    node.children.forEach((child) => this.printGraph(child));
    console.groupEnd();

  }

  /**
   * @param {Array<THREE.AnimationClip} clips
   */
  setClips ( clips ) {
    if (this.mixer) {
      this.mixer.stopAllAction();
      this.mixer.uncacheRoot(this.mixer.getRoot());
      this.mixer = null;
    }

    this.clips = clips;
    if (!clips.length) return;

    this.mixer = new AnimationMixer( this.content );
  }

  playAllClips () {
    this.clips.forEach((clip) => {
      this.mixer.clipAction(clip).reset().play();
      this.state.actionStates[clip.name] = true;
    });
  }

  /**
   * @param {string} name
   */
  setCamera ( name ) {
    if (name === DEFAULT_CAMERA) {
      this.controls.enabled = true;
      this.activeCamera = this.defaultCamera;
    } else {
      this.controls.enabled = false;
      this.content.traverse((node) => {
        if (node.isCamera && node.name === name) {
          this.activeCamera = node;
        }
      });
    }
  }

  updateTextureEncoding () {
    const encoding = this.state.textureEncoding === 'sRGB'
      ? sRGBEncoding
      : LinearEncoding;
    traverseMaterials(this.content, (material) => {
      if (material.map) material.map.encoding = encoding;
      if (material.emissiveMap) material.emissiveMap.encoding = encoding;
      if (material.map || material.emissiveMap) material.needsUpdate = true;
    });
  }

  updateLights () {
    const state = this.state;
    const lights = this.lights;

    if (state.addLights && !lights.length) {
      this.addLights();
    } else if (!state.addLights && lights.length) {
      this.removeLights();
    }

    this.renderer.toneMappingExposure = state.exposure;

    if (lights.length === 2) {
      lights[0].intensity = state.ambientIntensity;
      lights[0].color.setHex(state.ambientColor);
      lights[1].intensity = state.directIntensity;
      lights[1].color.setHex(state.directColor);
    }
  }

  addLights ()
  {
    const state = this.state;

    if (this.options.preset === Preset.ASSET_GENERATOR)
    {
      const hemiLight = new HemisphereLight();
      hemiLight.name = 'hemi_light';
      //this.scene.add(hemiLight);
      //this.lights.push(hemiLight);
      return;
    }

    this.setupLights();
  }

  removeLights () {

    this.lights.forEach((light) => light.parent.remove(light));
    this.lights.length = 0;

  }

  updateEnvironment () {
      const environment = environments.filter((entry) => entry.name === this.state.environment)[0];
    this.getCubeMapTexture( environment ).then(( { envMap } ) => {

      if ((!envMap || !this.state.background) && this.activeCamera === this.defaultCamera) {
        //this.scene.add(this.vignette);
      } else {
        //this.scene.remove(this.vignette);
      }

      this.scene.environment = envMap;
      //this.scene.background = envMap;//this.state.background ? envMap : null;

    });

  }

  getCubeMapTexture ( environment ) 
  {
    var {path} = environment;

    path = this.getEnvironment(path);

    // no envmap
    //if ( ! path ) return Promise.resolve( { envMap: null } );

    return new Promise( ( resolve, reject ) => 
    {
      if (!path)
      {
        resolve( { envMap: null } );

        this.setupSkybox();

        this.setupStateMachine();
      }
      else if (path.indexOf('.hdr') >= 0)
      {
        new RGBELoader()
        .setDataType( UnsignedByteType )
        .load( path, ( texture ) => {

          const envMap = this.pmremGenerator.fromEquirectangular( texture ).texture;
          this.pmremGenerator.dispose();

          resolve( { envMap } );

          this.setupSkybox();

          this.setupStateMachine();

        }, undefined, reject );
      }
      else
      {
        new TextureLoader().load( path, ( texture ) => {
          const envMap = this.pmremGenerator.fromEquirectangular( texture ).texture;
          this.pmremGenerator.dispose();

          resolve( { envMap } );

          this.setupSkybox(null);

          this.setupStateMachine();
          
        }, undefined, reject );
      }
    });

  }

  getEnvironment(_path)
  {
    if (paramJson != null)
    {
      if (paramJson.scene == 'vangogh')
      {
        return null;
      }
      else if (paramJson.scene == 'yuewen')
      {
        return 'assets/environment/reflection_yw.jpg';
        return null;
      }
      else if (paramJson.scene == 'dabaitu')
      {
        return 'assets/environment/reflection_dbt.jpg';
        return null;
      }
      else if (paramJson.scene == 'lianying')
      {
        return null;
      }
      else
      {
        return 'assets/environment/reflection.jpg';
        //return _path;
      }
    }

    return _path;
  }

  setupSkybox(skyboxTexture)
  {
    if (paramJson != null)
    {
      if (paramJson.scene == 'vangogh')
      {
        
      }
      else if (paramJson.scene == 'yuewen')
      {

      }
      else if (paramJson.scene == 'lianying')
      {

      }
      else if (paramJson.scene == 'dabaitu')
      {

      }
      else // Main scene
      {
        var _viewer = this;
        function createSkybox(texture)
        {
          var skyboxSize = 80;

          var geometry = new SphereBufferGeometry( skyboxSize, 50, 50 );
          var material = new MeshBasicMaterial( {map: texture, emissive:0xffffff, side : DoubleSide} );
          material.envMap = null;
      
          var mesh = new Mesh( geometry, material );
          mesh.position.y = 10;
          mesh.scale.x = -1.0;
          mesh.rotation.y = -Math.PI * 0.5;
          _viewer.scene.add(mesh);
          _viewer.stateMachine_Main.loadSkyboxHD(mesh.material);

          var geometry = new SphereBufferGeometry( skyboxSize - 0.1, 50, 50 );
          var material = new MeshBasicMaterial( {map: null, emissive:0xffffff, side : DoubleSide, transparent: true, visible : false} );
          var mesh = new Mesh( geometry, material );
          mesh.position.y = 10;
          mesh.scale.x = -1.0;
          mesh.rotation.y = -Math.PI * 0.5;
          _viewer.scene.add(mesh);

          _viewer.stateMachine_Main.beginToShineSkybox(mesh.material);
  
      
          function makeHeartShape( shape, extrudeSettings, color, x, y, z, rx, ry, rz, s )
          {
            var geometry = new ExtrudeBufferGeometry( shape, extrudeSettings );
      
            var mesh = new Mesh( geometry, new MeshStandardMaterial( { color: color} ) );
            mesh.position.set( x, y, z);
            mesh.rotation.set( rx, ry, rz );
            mesh.scale.set( s, s, s );
      
            return mesh;
          }
      
          var extrudeSettings = { depth: 8, bevelEnabled: true, bevelSegments: 2, steps: 2, bevelSize: 1, bevelThickness: 1 };
          var x = 0, y = 0;
      
          var heartShape = new Shape()
            .moveTo( x + 25, y + 25 )
            .bezierCurveTo( x + 25, y + 25, x + 20, y, x, y)
            .bezierCurveTo( x - 30, y, x - 30, y + 35, x - 30, y + 35 )
            .bezierCurveTo( x - 30, y + 55, x - 10, y + 77, x + 25, y + 95 )
            .bezierCurveTo( x + 60, y + 77, x + 80, y + 55, x + 80, y + 35 )
            .bezierCurveTo( x + 80, y + 35, x + 80, y, x + 50, y )
            .bezierCurveTo( x + 35, y, x + 25, y + 25, x + 25, y + 25 );
          
          var heartMesh = makeHeartShape( heartShape, extrudeSettings, 0xffffff, -(skyboxSize - 30), 15, skyboxSize - 30, 0, -0.1, Math.PI, 0.05);
          heartMesh.visible = false;
          _viewer.scene.add(heartMesh);
          _viewer.utils.heartIndicator = heartMesh;

          var vangoghMesh = makeHeartShape(heartShape, extrudeSettings, 0x2255ee, (skyboxSize - 30), 15, skyboxSize - 30, 0, 0.3, Math.PI, 0.05);
          vangoghMesh.visible = false;
          _viewer.scene.add(vangoghMesh);
          _viewer.utils.vangoghIndicator = vangoghMesh;
        }

        if (skyboxTexture != undefined)
        {
          createSkybox(skyboxTexture);
        }
        else
        {
          var skyboxTexturePath = "assets/environment/skybox_ld.jpg";
          new TextureLoader().load( skyboxTexturePath, ( texture ) => {
            createSkybox(texture);
          }, undefined, undefined );
        }   
      }
    }
  }

  setupLights()
  {
    if (paramJson != null)
    {
      if (paramJson.scene == 'vangogh')
      {
        var hemiLight = new HemisphereLight(0xffffff , 0x000000, 0.7);
        this.scene.add(hemiLight);
      }
      else if (paramJson.scene == 'yuewen')
      {
        var light1  = new AmbientLight(0xffffff, 1);
        //this.scene.add( light1 );

        var hemiLight = new HemisphereLight(0xffffff , 1.0);
        this.scene.add(hemiLight);
      }
      else if (paramJson.scene == 'dabaitu')
      {
        //const light1  = new AmbientLight(0xffffff, 2);
        //this.scene.add( light1 );

        var hemiLight = new HemisphereLight(0xffffff , 2.0);
        this.scene.add(hemiLight);
      }
      else if (paramJson.scene == 'lianying')
      {
        var light1  = new AmbientLight(0xffffff, 2);//state.ambientIntensity);
        this.scene.add(light1);
      }
      else
      {
        var light1  = new AmbientLight(0xffffff, 1);//state.ambientIntensity);
        //light1.name = 'ambient_light';
        //this.scene.add( light1 );
    
        // const light2  = new DirectionalLight(0x7A66B2, state.directIntensity);
        // light2.position.set(0.5, 0, 0.866); // ~60º
        // //light2.name = 'main_light';
        // light2.layers.set(3);
        // this.defaultCamera.add( light2 );
    
        var light3  = new PointLight(0x7A66B2, 15, 50 );
        light3.position.set(0, 29.0, 0.0); // ~60º
        //light3.name = 'main_light';
        //this.scene.add( light3 );
    
        // var geometry = new SphereBufferGeometry( 10, 50, 50 );
        // var material = new MeshLambertMaterial( {emissive:0x555555, side : DoubleSide} );
        // var mesh = new Mesh( geometry, material );
        // mesh.position.set(0, 29.0, 0.0);
        // this.scene.add(mesh)
    
        //this.lights.push(light1, light2);
      }
    }
  }

  setupCamera()
  {
    if (paramJson != null)
    {
      if (paramJson.scene == 'vangogh')
      {
        // this.defaultCamera.position.set(-0.3710403633734554, 1.5395290524564806, 5.860783659499081);

        this.defaultCamera.position.x = 0;
        this.defaultCamera.position.y = 0;
        this.defaultCamera.position.z = 1.8;
        // this.controls.target.set(-0.4636236881785793, 1.5934175788318112, 0.1499615886576653);

        // this.controls.enableZoom = false;
        // this.controls.enablePan = false;

        // this.controls.minPolarAngle = Math.PI * 0.47;
        // this.controls.maxPolarAngle = Math.PI * 0.53;

        // this.controls.minAzimuthAngle = -0.07;//-Math.PI * 0.5;
        // this.controls.maxAzimuthAngle = 0.07;//Math.PI * 1.5;
      }
      else if (paramJson.scene == 'dabaitu')
      {
        this.defaultCamera.position.x = 2.26;
        this.defaultCamera.position.y = -4;
        this.defaultCamera.position.z = 12.64;
      }
      else if (paramJson.scene == 'yuewen')
      {
        //todo,change camera pos
        this.defaultCamera.position.x = -1.73;
        this.defaultCamera.position.y = -8.5;
        this.defaultCamera.position.z = -7.08;
        this.defaultCamera.far *= 0.1;
        this.defaultCamera.near *= 0.1;
        this.defaultCamera.updateProjectionMatrix();
      }
      else if (paramJson.scene == 'lianying')
      {
        this.defaultCamera.position.x = -10;
        this.defaultCamera.position.y = 0;
        this.defaultCamera.position.z = 0;
        var euler = new Euler(0, 55, 0);
        this.defaultCamera.quaternion.setFromEuler(euler);
      }
      else // Main scene
      {
        this.defaultCamera.position.x = 0.25;
        this.defaultCamera.position.y = 5;
        this.defaultCamera.position.z = -40;
        var euler = new Euler(-3.07, -0.01, -3.14);
        this.defaultCamera.quaternion.setFromEuler(euler);

        //this.controls.enableZoom = false;
        this.controls.enablePan = false;
        //this.controls.enableRotate = false;
      }
    }
  }

  setupStateMachine()
  {
    if (paramJson != null)
    {
      if (this.stateMachine != null)
      {
        this.stateMachine.start();
      }

      // this.beginToFadeOut();
    } 
  }

  beginToFadeOut()
  {
    document.getElementById("fadeout").style.opacity = 0.0;
    setTimeout(function(){
      document.getElementById("fadeout").style.display = 'none';
    }, 600);
  }

  updateDisplay () {
    if (this.skeletonHelpers.length) {
      this.skeletonHelpers.forEach((helper) => this.scene.remove(helper));
    }

    traverseMaterials(this.content, (material) => {
      material.wireframe = this.state.wireframe;
    });

    this.content.traverse((node) => {
      if (node.isMesh && node.skeleton && this.state.skeleton) {
        const helper = new SkeletonHelper(node.skeleton.bones[0].parent);
        helper.material.linewidth = 3;
        this.scene.add(helper);
        this.skeletonHelpers.push(helper);
      }
    });

    if (this.state.grid !== Boolean(this.gridHelper)) {
      if (this.state.grid) {
        this.gridHelper = new GridHelper();
        this.axesHelper = new AxesHelper();
        this.axesHelper.renderOrder = 999;
        this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
        this.scene.add(this.gridHelper);
        this.scene.add(this.axesHelper);
      } else {
        this.scene.remove(this.gridHelper);
        this.scene.remove(this.axesHelper);
        this.gridHelper = null;
        this.axesHelper = null;
        this.axesRenderer.clear();
      }
    }
  }

  updateBackground () {
    this.vignette.style({colors: [this.state.bgColor1, this.state.bgColor2]});
  }

  /**
   * Adds AxesHelper.
   *
   * See: https://stackoverflow.com/q/16226693/1314762
   */
  addAxesHelper () {
    this.axesDiv = document.createElement('div');
    this.el.appendChild( this.axesDiv );
    this.axesDiv.classList.add('axes');

    const {clientWidth, clientHeight} = this.axesDiv;

    this.axesScene = new Scene();
    this.axesCamera = new PerspectiveCamera( 50, clientWidth / clientHeight, 0.1, 1000 );
    this.axesScene.add( this.axesCamera );

    this.axesRenderer = new WebGLRenderer( { alpha: true } );
    this.axesRenderer.setPixelRatio( window.devicePixelRatio );
    this.axesRenderer.setSize( this.axesDiv.clientWidth, this.axesDiv.clientHeight );

    this.axesCamera.up = this.defaultCamera.up;

    this.axesCorner = new AxesHelper(5);
    this.axesScene.add( this.axesCorner );
    this.axesDiv.appendChild(this.axesRenderer.domElement);
  }

  addGUI () {

    const gui = this.gui = new GUI({autoPlace: false, width: 260, hideable: true});

    var _viewer = this;
    // Boken controls
    var matChanger = function ( ) {

      console.log(_viewer.bokehPass);

      _viewer.bokehPass.uniforms[ "focus" ].value = _viewer.BokenEffectController.focus * 0.1;
      _viewer.bokehPass.uniforms[ "aperture" ].value = _viewer.BokenEffectController.aperture * 0.00001;
      _viewer.bokehPass.uniforms[ "maxblur" ].value = _viewer.BokenEffectController.maxblur;

      console.log('adjus boken params');
    };

    const bokenFolder = gui.addFolder('Boken');
    bokenFolder.add( this.BokenEffectController, "focus", 1.0, 200.0, 0.5).onChange( matChanger );
    bokenFolder.add( this.BokenEffectController, "aperture", 0, 10, 0.1 ).onChange( matChanger );
    bokenFolder.add( this.BokenEffectController, "maxblur", 0.0, 3.0, 0.025 ).onChange( matChanger );

    // Bloom controls
    const bloomFolder = gui.addFolder('Bloom');
    bloomFolder.add(this.params, 'bloomThreshold', 0.0, 1).onChange( function ( value ) {
      _viewer.bloomPass.threshold = Number( value );
      console.log('bloomThreshold: ' + _viewer.bloomPass.threshold);
    } );
    bloomFolder.add( this.params, 'bloomStrength', 0.0, 3.0 ).onChange( function ( value ) {
      _viewer.bloomPass.strength = Number( value );
      console.log('bloomStrength: ' + _viewer.bloomPass.strength);
    } );
    bloomFolder.add( this.params, 'bloomRadius', 0.0, 1.0).step( 0.1 ).onChange( function ( value ) {
      _viewer.bloomPass.radius = Number( value );
    } );

    // Display controls.
    const dispFolder = gui.addFolder('Display');
    const envBackgroundCtrl = dispFolder.add(this.state, 'background');
    envBackgroundCtrl.onChange(() => this.updateEnvironment());
    const wireframeCtrl = dispFolder.add(this.state, 'wireframe');
    wireframeCtrl.onChange(() => this.updateDisplay());
    const skeletonCtrl = dispFolder.add(this.state, 'skeleton');
    skeletonCtrl.onChange(() => this.updateDisplay());
    const gridCtrl = dispFolder.add(this.state, 'grid');
    gridCtrl.onChange(() => this.updateDisplay());
    dispFolder.add(this.controls, 'autoRotate');
    dispFolder.add(this.controls, 'screenSpacePanning');
    const bgColor1Ctrl = dispFolder.addColor(this.state, 'bgColor1');
    const bgColor2Ctrl = dispFolder.addColor(this.state, 'bgColor2');
    bgColor1Ctrl.onChange(() => this.updateBackground());
    bgColor2Ctrl.onChange(() => this.updateBackground());

    // Lighting controls.
    const lightFolder = gui.addFolder('Lighting');
    const encodingCtrl = lightFolder.add(this.state, 'textureEncoding', ['sRGB', 'Linear']);
    encodingCtrl.onChange(() => this.updateTextureEncoding());
    lightFolder.add(this.renderer, 'outputEncoding', {sRGB: sRGBEncoding, Linear: LinearEncoding})
      .onChange(() => {
        this.renderer.outputEncoding = Number(this.renderer.outputEncoding);
        traverseMaterials(this.content, (material) => {
          material.needsUpdate = true;
        });
      });
    const envMapCtrl = lightFolder.add(this.state, 'environment', environments.map((env) => env.name));
    envMapCtrl.onChange(() => this.updateEnvironment());
    [
      lightFolder.add(this.state, 'exposure', 0, 2),
      lightFolder.add(this.state, 'addLights').listen(),
      lightFolder.add(this.state, 'ambientIntensity', 0, 2),
      lightFolder.addColor(this.state, 'ambientColor'),
      lightFolder.add(this.state, 'directIntensity', 0, 4), // TODO(#116)
      lightFolder.addColor(this.state, 'directColor')
    ].forEach((ctrl) => ctrl.onChange(() => this.updateLights()));

    // Animation controls.
    this.animFolder = gui.addFolder('Animation');
    this.animFolder.domElement.style.display = 'none';
    const playbackSpeedCtrl = this.animFolder.add(this.state, 'playbackSpeed', 0, 1);
    playbackSpeedCtrl.onChange((speed) => {
      if (this.mixer) this.mixer.timeScale = speed;
    });
    this.animFolder.add({playAll: () => this.playAllClips()}, 'playAll');

    // Morph target controls.
    this.morphFolder = gui.addFolder('Morph Targets');
    this.morphFolder.domElement.style.display = 'none';

    // Camera controls.
    this.cameraFolder = gui.addFolder('Cameras');
    this.cameraFolder.domElement.style.display = 'none';

    // Stats.
    const perfFolder = gui.addFolder('Performance');
    const perfLi = document.createElement('li');
    this.stats.dom.style.position = 'static';
    perfLi.appendChild(this.stats.dom);
    perfLi.classList.add('gui-stats');
    perfFolder.__ul.appendChild( perfLi );

    const guiWrap = document.createElement('div');
    this.el.appendChild( guiWrap );
    guiWrap.classList.add('gui-wrap');
    guiWrap.appendChild(gui.domElement);
    gui.open();

  }

  updateClips()
  {
      this.clips.forEach((clip, clipIndex) => {
          // Autoplay the first clip.
          let action;
          if (clipIndex === 0) {
              //actionStates[clip.name] = true;
              action = this.mixer.clipAction(clip);
              action.play();
          } 
          else 
          {
              //actionStates[clip.name] = false;
          }
      });
  }

  updateGUI () {
    this.cameraFolder.domElement.style.display = 'none';

    this.morphCtrls.forEach((ctrl) => ctrl.remove());
    this.morphCtrls.length = 0;
    this.morphFolder.domElement.style.display = 'none';

    this.animCtrls.forEach((ctrl) => ctrl.remove());
    this.animCtrls.length = 0;
    this.animFolder.domElement.style.display = 'none';

    const cameraNames = [];
    const morphMeshes = [];
    this.content.traverse((node) => {
      if (node.isMesh && node.morphTargetInfluences) {
        morphMeshes.push(node);
      }
      if (node.isCamera) {
        node.name = node.name || `VIEWER__camera_${cameraNames.length + 1}`;
        cameraNames.push(node.name);
      }
    });

    if (cameraNames.length) {
      this.cameraFolder.domElement.style.display = '';
      if (this.cameraCtrl) this.cameraCtrl.remove();
      const cameraOptions = [DEFAULT_CAMERA].concat(cameraNames);
      this.cameraCtrl = this.cameraFolder.add(this.state, 'camera', cameraOptions);
      this.cameraCtrl.onChange((name) => this.setCamera(name));
    }

    if (morphMeshes.length) {
      this.morphFolder.domElement.style.display = '';
      morphMeshes.forEach((mesh) => {
        if (mesh.morphTargetInfluences.length) {
          const nameCtrl = this.morphFolder.add({name: mesh.name || 'Untitled'}, 'name');
          this.morphCtrls.push(nameCtrl);
        }
        for (let i = 0; i < mesh.morphTargetInfluences.length; i++) {
          const ctrl = this.morphFolder.add(mesh.morphTargetInfluences, i, 0, 1, 0.01).listen();
          Object.keys(mesh.morphTargetDictionary).forEach((key) => {
            if (key && mesh.morphTargetDictionary[key] === i) ctrl.name(key);
          });
          this.morphCtrls.push(ctrl);
        }
      });
    }

    if (this.clips.length) {
      this.animFolder.domElement.style.display = '';
      const actionStates = this.state.actionStates = {};
      this.clips.forEach((clip, clipIndex) => {
        // Autoplay the first clip.
        let action;
        if (clipIndex === 0) {
          actionStates[clip.name] = true;
          action = this.mixer.clipAction(clip);
          action.play();
        } else {
          actionStates[clip.name] = false;
        }

        // Play other clips when enabled.
        const ctrl = this.animFolder.add(actionStates, clip.name).listen();
        ctrl.onChange((playAnimation) => {
          action = action || this.mixer.clipAction(clip);
          action.setEffectiveTimeScale(1);
          playAnimation ? action.play() : action.stop();
        });
        this.animCtrls.push(ctrl);
      });
    }
  }

  clear () {

    if ( !this.content ) return;

    this.scene.remove( this.content );

    // dispose geometry
    this.content.traverse((node) => {

      if ( !node.isMesh ) return;

      node.geometry.dispose();

    } );

    // dispose textures
    traverseMaterials( this.content, (material) => {

      MAP_NAMES.forEach( (map) => {

        if (material[ map ]) material[ map ].dispose();

      } );

    } );

  }

};

function traverseMaterials (object, callback) {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    materials.forEach(callback);
  });
}
