export const environments = [
  {
    id: '',
    name: 'None',
    path: null,
    format: '.hdr'
  },
  {
    id: 'venice-sunset',
    name: 'Venice Sunset',
    path: 'assets/environment/reflection.jpg',
    format: '.jpg'
  }
];
