// import '../node_modules/nipplejs/dist/nipplejs.js';

export class Joystick {
  constructor(opt) {
    var disabledColor = opt && opt.disabledColor || true;
    this.options = {
      mode: opt && opt.mode || 'static',
      size: opt && opt.size || 150,
      color: disabledColor ? 'ddd' : (opt && opt.color || '#eee'),
      position: opt && opt.position || {
        left: '50%',
        bottom: '18%'
      },
      zone: opt && opt.zone
    }


    this.distance = 0;
    this.angle = null;
    this.time = null;

  }

  init() {
    var manager = require('nipplejs').create(this.options);
    this.manager = manager;
    this._on();
    console.log("JotStick Init");
    let nipple = document.getElementById("nipple_0_0");
    nipple.style.scale = 0.3;
    return this;
  }

  // onStart(action) {
  //   let me = this;
  //   me.time = setInterval(() => {
  //     me.onStart && me.onStart(me.distance, me.angle);
  //   }, 20);
  //   action(evt, data);
  // }

  _on() {
    let me = this;
    this.manager.on('start', function (evt, data) {
      me.time = setInterval(() => {
        me.onStart && me.onStart(me.distance, me.angle);
      }, 20);
    })
    this.manager.on('move', function (evt, data) {
      if (data.direction) {
        me.angle = data.direction.angle;
        me.distance = data.distance;
      }
    })
    this.manager.on('end', function (evt, data) {
      clearInterval(me.time);
      me.onEnd && me.onEnd();
    });
  }
}