import { Viewer } from './viewer.js';
import { Vector3, Raycaster, Vector2, Color, Euler } from 'three';
import { Joystick } from "./joystick.js";
import { Vector4 } from 'three/build/three.module';

class StartState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;

		this.duration = 5.0;
		this.timer = 0.0;

		this.isOver = false;

		this.start = function () {
			console.log('start state: ' + this.name);
			// ShowBackIcon();
			let jsonvalue1 = window.configJson.IconPaths.dabaitushuoming;
			let jsonvalue2 = window.configJson.IconPaths.dabaitufenxiang;
			let alt = "dabaitu";
			
			PushTrackEvent('大白兔','访问');
			ShowAndChangePictureTopEX(jsonvalue1, jsonvalue2,true,window.configJson.LogoDetailPoints[7].tiaozhuan,alt);

			//this.params.camera.position.set(-3.2653076981286837, 122.46687236701582, 449.2028617138161);

			//this.params.controls.target.set(-33.67245488013309, 120.88745398604473, 3.2812605880604657);
			window.app.ShowHint(window.configJson.SceneIntro.dabaitu);
		}

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}

			if (this.timer >= this.duration) {
				this.isOver = true;

				this.end();
			}
			else {

			}

			this.timer += deltaTime;
		}
	}
}

class FreeMoveState {
	constructor(options) {
		this.name = options.name;
		this.params = options.params;
		this.navMesh = options.navMesh;
		this.owner = options.owner;
		this.duration = 1.0;
		this.timer = 0.0;
		this.testSphereList = [];
		this.isOver = false;
		var euler = new Euler(0, 0, 0, 'YXZ');
		var PI_2 = Math.PI / 2;
		var vec = new Vector3();
		var old = new Vector3();
		this.nowTriangle = [];
		var orbCamera = this.params.camera;
		var speed = window.configJson.MoveSpeed.dabaitu;
		this.start = function () {
			console.log('start state: ' + this.name);
			this.CreateJoyStick();
			this.CreateInteractPots();
			this.addAllEventListener();
			
			let jsonPoints = window.configJson.LogoDetailPoints;
			const jsonPoint = jsonPoints[7];
			let path = window.configJson.IconPaths.pinpai_intro;
			window.app.viewer.addImageObject(path, function () {
				if (GetOpenState() == false) {
					OpenPicture(jsonPoint);
					PushTrackEvent('大白兔','查看品牌介绍');
					document.getElementsByClassName('bottom-btns-top-ex')[0].style.display = 'none';
					window.isopentopex=true;
				}

			}, new Vector3(2.3, -5, 8.42), new Vector3(1.5, 0.75, 1), new Vector3(0, Math.PI, 0), function (imageObject) {
				
			}, true);

			let bgmPath = window.configJson.AudioPath.dabaitubgm;
			let pangbaiPath = window.configJson.AudioPath.dabaitupangbai;
			window.app.CreateAudioPlayerList(bgmPath,pangbaiPath,"jingying","shengying");
		}

		this.addAllEventListener = function () {
			window.renderer.domElement.addEventListener('mousedown', this.downClbk);
			window.renderer.domElement.addEventListener("touchstart", this.downClbk);
			window.renderer.domElement.addEventListener('mouseup', this.upClbk);
			window.renderer.domElement.addEventListener("touchend", this.upClbk);
			window.renderer.domElement.addEventListener("touchcancel", this.upClbk);

		}

		this.CreateJoyStick = function () {
			var joy = new Joystick({ zone: document.getElementById("left") }).init();
			joy.onStart = function (distance, angle) {
				old.copy(orbCamera.position);
				let val = distance * delTime * speed;
				switch (angle) {
					case 'up':
						self.moveForward(val);
						break;
					case 'right':
						self.moveRight(val);
						break;
					case 'left':
						self.moveLeft(val);
						break;
					case 'down':
						self.moveBack(val);
						break;
				};
			}
		}


		var iconList = [];
		var wenziList = [];
		var billboradList = [];
		var iconList = [];
		this.CreateInteractPots = function () {
			let owner = this.owner;
			let json = window.configJson.DabaituPots;
			let dabaitu = window.configJson.IconPaths.dabaitu;
			for (let i = 0; i < 8; i++) {
				let index = i;
				let ele = json[i];
				let wenziUrl = ele.wenziUrl;
				let showPicurl = ele.showPic;
				let potPos = new Vector3(ele.potPos[0], ele.potPos[1], ele.potPos[2]);
				let wenziPos = new Vector3().copy(potPos).setY(potPos.y+2);
				let potRot = new Vector3(ele.potRot[0], ele.potRot[1], ele.potRot[2]);
				let wenziRot = new Vector3(ele.wenziRot[0], ele.wenziRot[1], ele.wenziRot[2]);
				let dummyId = ele.id;
				let dummy = owner.getDummyByName(('p' + dummyId));
				let showPicPos = new Vector3().copy(potPos);
				let showPicRot = new Vector3().copy(dummy.rotation);
				let shineName = ele.shineName;
				window.app.viewer.addImageObject((dabaitu + wenziUrl), null, wenziPos, new Vector3(2.244, 0.332, 1), new Vector3(0, 3.14, 0), function (imageObject) {
						wenziList[index] = imageObject;
						imageObject.visible = false;
				}, true);

				window.app.viewer.addImageObject((dabaitu + showPicurl), null, showPicPos, new Vector3(4, 4, 1), showPicRot, function (imageObject) {
					billboradList[index] = imageObject;
					imageObject.visible = false;
				}, true, "naiping", false);
				
				let hintPath = (dabaitu + wenziUrl);
				let iconCircle = window.configJson.IconPaths.blueCircle;
				window.app.viewer.addImageObject(iconCircle, function () {
					if (!window.canClick) {
						return;
					}
					iconList[index].visible = false;
					billboradList[index].visible = true;
					var hint = OpenNormalPictureWithoutExit(hintPath);
					window.canClick = false;
					let canvas = document.getElementsByClassName('viewer-canvas')[0];
					canvas.addEventListener('click', hintClick);
					
					function hintClick() {
						hint.hide();
						// billboradList[index].visible = false;
						iconList[index].visible = true;
						window.canClick = true;
						canvas.removeEventListener('click', hintClick);
					}

					owner.switchMeshEmissive(shineName, true, 0.1, new Color(0.8, 0.6, 0.3));
				}, potPos, new Vector3(1.3, 1.3, 1.3), new Vector3(0, Math.PI, 0), function (imageObject) {
						iconList[index] = imageObject;
				}, true);

			}

			//房子上的抖音视频1
			let videoWhite = window.configJson.IconPaths.whiteVideo;
			// window.app.viewer.addImageObject(videoWhite, function()
			// {
			// 	let path = window.configJson.VideoPaths.dabaitu_douyin;
			// 	lightbox_open(path);
			// }, new Vector3(1, -6.7, 10), new Vector3(0.7, 0.7, 1), new Vector3(), function (imageObject) {

			// }, true);

			//房子上的抖音视频2
			window.app.viewer.addImageObject(videoWhite, function()
			{
				let path = window.configJson.VideoPaths.dabaitu_douyin;
				lightbox_open(path);
				PushTrackEvent('大白兔','查看抖音视频');
			}, new Vector3(-1, -6.7, 7.5), new Vector3(0.7, 0.7, 1), new Vector3(), function (imageObject) {

			}, true);

			// videoWhite = window.configJson.IconPaths.dabaitu_gouwu;
			// window.app.viewer.addImageObject(videoWhite, function()
			// {
			// 	// let path = window.configJson.VideoPaths.dabaitu_intro;
			// 	// lightbox_open(path);
			// 	window.open(window.configJson.LogoDetailPoints[7].tiaozhuan, '_self', false);
			// }, new Vector3(-1.3, -4, 8.42), new Vector3(3, 1, 1), new Vector3(), function (imageObject) {

			// }, true);
		}

		
		var self = this;
		var mouse = new Vector2();
		this.downClbk = function (event) {
			if (event.type == "touchstart") {
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			}
			window.renderer.domElement.addEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.addEventListener("touchmove", self.moveClbk);
		}

		this.upClbk = function (e) {
			window.renderer.domElement.removeEventListener('mousemove', self.moveClbk);
			window.renderer.domElement.removeEventListener("touchmove", self.moveClbk);
		}

		this.moveClbk = function (event) {
			var movementX = null;
			var movementY = null;
			euler.setFromQuaternion(orbCamera.quaternion);
			if (event.type == "touchmove") {
				movementX = (event.touches[event.touches.length - 1].clientX - mouse.x) * 3 || 0;
				movementY = (event.touches[event.touches.length - 1].clientY - mouse.y) * 3 || 0;
				mouse.setX(event.touches[event.touches.length - 1].clientX);
				mouse.setY(event.touches[event.touches.length - 1].clientY);
			} else {
				movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
				movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;
			}
			euler.y -= movementX * 0.002;
			euler.x -= movementY * 0.002;
			euler.x = Math.max(- PI_2, Math.min(PI_2, euler.x));
			orbCamera.quaternion.setFromEuler(euler);
		}

		this.checkLeagel = function(vec,distance)
		{
			let result = this.checkDisValLeagel(vec,distance);
			result = this.checkDisValLeagel(result.vec,result.distance);
			return result;
		}

		this.checkDisValLeagel = function (vec, distance) 
		{
			let normalizedVec = new Vector3().copy(vec).normalize();
			let nextPos = new Vector3().copy(orbCamera.position).addScaledVector(normalizedVec, distance).setY(0);

			let isNextPosValid = window.app.IsPosValid(self.navMesh, nextPos);
			if (isNextPosValid) {
				return { vec: normalizedVec, distance: distance };
			}
			let result = this.onDisUnleagel(nextPos,distance);
			return {vec:result.vec,distance:result.distance};
			
		}

		this.onDisUnleagel=function(nextPos,distance)
		{
			let orbCamPos = new Vector3().copy(orbCamera.position).setY(0);
			let ownerTriangleList = window.app.GetOwnerTriangle(self.navMesh, orbCamPos);
			if(ownerTriangleList==null)
			{
				return {vec:new Vector3(),distance:distance};
			}
			this.nowTriangle = window.app.SetToLeftOrder(ownerTriangleList);
			let overEdge = window.app.FindOverEdge(this.nowTriangle, nextPos);
			let edge = new Vector3().subVectors(overEdge[1], overEdge[0]).normalize();
			let edgeCross = new Vector3();
			edgeCross.setX(-edge.z);
			edgeCross.setZ(edge.x).normalize();
			let dir = new Vector3().subVectors(nextPos, orbCamPos).normalize();
			let angle = dir.dot(edgeCross);
			if (angle < 0) {
				edgeCross.multiplyScalar(-1);
			}
			let finalDir = new Vector3().subVectors(dir, edgeCross).normalize();

			return { vec: finalDir, distance: distance };
		}

		this.CreateTestSphere = function () {
			for (let i = 0; i < 3; i++) {
				let sph = window.app.CreateSphere(new Vector3());
				this.testSphereList.push(sph);
			}
		}

		this.SetTestSphere = function (triangle) {
			for (let i = 0; i < 3; i++) {
				this.testSphereList[i].position.copy(triangle[i]);
			}
		}
		

		this.onRunOutOfRange = function (v1, v2, nextPos) {
			let cor = new Vector3().subVectors(v1, v2).normalize();
			let newCamPos = new Vector3().copy(orbCamera.position).setY(0);
			let newNextPos = new Vector3().copy(nextPos).setY(0);
			let dir = new Vector3().subVectors(newCamPos, newNextPos).normalize();
			let line = new Vector3().subVectors(dir, cor).normalize();
			return line;
		}


		this.outputVec = function (name, vec) {
			console.log(name + ": " + vec.x + "," + vec.y + "," + vec.z);
		}

		this.moveForward = function (distance) {
			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			//获取四元数中x项
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			//与up叉乘算出forward;
			vec.crossVectors(orbCamera.up, vec);

			let opts = this.checkLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.moveBack = function (distance) {
			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			//获取四元数中x项
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			//与up叉乘算出forward;
			vec.setX(-vec.x);
			vec.setY(-vec.y);
			vec.setZ(-vec.z);
			vec.crossVectors(orbCamera.up, vec);

			let opts = this.checkLeagel(vec, distance);
			// if (true) {
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
			// }
		};

		this.moveRight = function (distance) {
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			let opts = this.checkLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.moveLeft = function (distance) {
			vec.setFromMatrixColumn(orbCamera.matrix, 0);
			vec.setX(-vec.x);
			vec.setY(-vec.y);
			vec.setZ(-vec.z);
			let opts = this.checkLeagel(vec, distance);
			orbCamera.position.addScaledVector(opts.vec, opts.distance);
		};

		this.end = function () {
			console.log('end state: ' + this.name);
		}

		var delTime = 0.02;
		this.update = function (deltaTime) {
			if (this.isOver) {
				return;
			}
			// self.outputVec("now pos is ", orbCamera.position);
			// delTime = deltaTime;
		}
	}
}


export class StateMachine_dabaitu {
	constructor(options) {
		this.states = [];
		this.activeState = -1;
		this.isStarted = false;
		this.options = options;

		this.navMesh = [];

		this.dynObjects = {};
		this.meshMap = {};
		this.dummyObjects=[];
		this.start = function () {
			this.isStarted = true;

			this.states[this.activeState].start();

			/*
			var isEmissive = true;
			var ss = this;
			setInterval(function()
			{
				isEmissive = !isEmissive;
				ss.switchMeshEmissive('Home_01', isEmissive, 0.5, new Color(0.8, 0.9, 0.0));
			}, 500);
			//*/
		}

		this.getDummyByName = function(name)
		{
			//.position为dummy点的位置
			//.rotation为dummy点的旋转
			return this.dummyObjects[name];
		}

		this.switchMeshEmissive = function(name, showEmissive, strength, color)
		{
			if (this.meshMap[name])
			{
				var meshGroup = this.meshMap[name];
				for (var i = 0 ; i < meshGroup.length; ++i)
				{
					if (showEmissive)
					{
						meshGroup[i].layers.enable(1);
						
						if (color)
						{
							meshGroup[i].material.color = color;
						}
						else
						{
							var value = 0.38 + 0.65 * strength;
							meshGroup[i].material.color = new Color(value, value, value);
						}
					}
					else
					{
						meshGroup[i].layers.disable(1);
						meshGroup[i].material.color = new Color(1.0, 1.0, 1.0);
					}
				}
			}

			if (strength != undefined)
			{
				this.options.viewer.setEmissiveStrength(strength);
			}
		}

		this.initializeStates = function () {
			this.activeState = 0;

			this.states.push(new StartState({ name: 'StartState', params: this.options }));
			this.states.push(new FreeMoveState({ name: 'Freemove', params: this.options, navMesh: this.navMesh , owner: this}));
		}

		this.update = function (deltaTime) {
			if (this.isStarted == false) {
				return;
			}

			if (this.activeState >= 0 && this.activeState < this.states.length) {
				this.states[this.activeState].update(deltaTime);

				if (this.states[this.activeState].isOver) {
					// Jump to next
					this.activeState++;

					if (this.activeState < this.states.length) {
						this.states[this.activeState].start();
					}
				}
			}
		}

		this.initializeStates();
	}
}
